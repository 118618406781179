import { get, isNil, set, find, filter, head, reverse } from "lodash";
import React, { Suspense, createRef, lazy } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ToastType } from "react-toastify";
import zipy from "zipyai";

import * as rdd from 'react-device-detect';
import {
  Button, CustomInput, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Nav,
  Navbar, NavbarBrand, Row, UncontrolledDropdown,Col
} from "reactstrap";
import { t } from "../../../infrastructure/localization/localizationservice";
import {
  logout,
  setLeftSideBarActiveMenu,
  setLeftNavigationActiveMenu,
  uploadProfilePicture,
  userMetaDataUpdate,
  updateIndexMetaDataInAllApplication
} from "../../../shared/services/appData/Actions";
import { PAGES, PHOTO_FILE_PARAMETERS, STORAGE_KEY, STORAGE_TYPE, LEAD_EVENT_MASTER_KEYS, BLOB_FOLDERS, BLOB_NAMES } from "../../utilties/Constants";
import { OfflineStorage } from "../../utilties/OfflineStorage";
import {
  fileNameLengthValidate,
  findAndReplaceInString,
  getLang,
  isLangExist,
  notify,
  updateUserInfoFromFormData,
  leadObjectManager,
  isEmpty,
  renameFileByToken,
  replaceSpecialCharacter,
  getMasterDataByKey,
  getValue,
  getProfilePicture,
  addURLParametersAndRoute,
  checkIfObject,
  logoutApplicationForm,
  checkIfArray,
  updateParamtersAPIForIsLeadOnline,
  getIsLeadOnlineAPIParams,
  generateSignature,
  getFileNameFromBlob
} from "../../utilties/Utils";
import IdleTimer from "react-idle-timer";
import "./Header.scss";
import HamBurger from "../../icons/hamburger.svg"
import { Services } from "../../../shared/services/appData/Services";
import { SuspendRoute } from "../../../App";
import ArrowBack from "../../../shared/icons/arrow-back.svg";
import CommunicationHeaderIcon from "../../../shared/icons/communication-icon.svg"
import ProfileImageWithLoader from "../ProfileImageWithloader/ProfileImageWithLoader";

const LazyMenuNavigationComponent = React.lazy(() =>
  import("../MenuNavigation/index")
);

const LazyCommunication = lazy(() => import("../Communication"));
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.onlineStatusIdleTimerRef = null;
    this.isOnlineStatusActive = !!get(this.props,"clientInfo.onlineStatus.isActive");
    this.idleTimerTimeout = get(this.props,"clientInfo.onlineStatus.idleTime") ? parseInt(get(this.props,"clientInfo.onlineStatus.idleTime")) : 1000 * 15 * 60;
    this.state = {
      isOpen: false,
      isLeftConfirmationPopupOpen: false,
      isShowProfileUpdatePopup: false,
      profilePhotoFileName: "",
      invalidProfileFile: false,
      profilePhotoFile: null,
      profilePhotoObject: null,
      invalidFileMessage: "",
      leadId : get(this.props,'userDetail.lead.id'),
      onlineStatus: "offline",
      showCommunicationPopup: false,
      profilePhotoUrl : null
    };
    this.isWindowListenerAdded=false;
    this.isOnlineStatusActive && this.addWindowListener();
    this.isPreviewTemplateModalOpen = createRef(false);
    this.leftSideBarRef = createRef(false)
    this.avatarUrlRef = createRef(null);
  }
  updateIsPreviewTemplateModalRef = (data) => {
    this.isPreviewTemplateModalOpen.current = data;
  }
  addWindowListener=()=>{   
    if(!this.isWindowListenerAdded){
      this.isWindowListenerAdded=true;  
      window.onbeforeunload = (e) => {
        if(get(this.props,"userDetail.lead.id")){
          this.callSyncOnlineStatusAPI && this.callSyncOnlineStatusAPI();
        }
        return null;
      }
      window.onpagehide = (e) => {
        if(get(this.props,"userDetail.lead.id")){
          this.callSyncOnlineStatusAPI && this.callSyncOnlineStatusAPI();
        }
        return null;
      }
    }   
  }
  /**
   * @description Method to toggle the drop down menus visbility
   */
  toggle=(event)=> {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  /**
   * @description Log-out button's click handler
   */
  
  onLogoutClicked=(e)=> {
    const { logout, history, clientInfo} = this.props;
    e.preventDefault();
    e.stopPropagation();
    this.toggleModal(e);
    if(this.isOnlineStatusActive){
      this.callSyncOnlineStatusAPI && this.callSyncOnlineStatusAPI();
      this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.reset();
      this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.pause();
    }
    logoutApplicationForm({logout, clientInfo, history});
    zipy && zipy.anonymize();

    // logout && logout();
    // let searchParams={};          
    // !!OfflineStorage.getItem(STORAGE_KEY.INST,STORAGE_TYPE.LOCAL) && set(searchParams,STORAGE_KEY.INST,OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL));

    // !!OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,STORAGE_TYPE.LOCAL) && set(searchParams,STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, STORAGE_TYPE.LOCAL)); 
    
    // const allOfflineStorageData = OfflineStorage.getAllItem();

    // if(checkIfObject(allOfflineStorageData)){
    //   Object.keys(allOfflineStorageData).forEach(lsKey => {
    //     if(isNil(get(searchParams,lsKey)) && !isNil(OfflineStorage.getItem(lsKey,STORAGE_TYPE.LOCAL))) 
    //     OfflineStorage.deleteItem(lsKey, STORAGE_TYPE.LOCAL)
    //   });
    // }else{
    //   OfflineStorage.clear(STORAGE_TYPE.LOCAL);
    //   OfflineStorage.clear();
    // }
    // let logoutActive = !!get(this.props,`clientInfo.afterLogoutRedirect.isActive`);
    // let logoutRedirectUrl = get(this.props,`clientInfo.afterLogoutRedirect.href`);
    
    // zipy && zipy.anonymize();
    // if(logoutActive && !!logoutRedirectUrl){
    //   window.location.href = logoutRedirectUrl;
    //   return;
    // }else if (searchParams && Object.keys(searchParams).length) {
    //   history.push({
    //     pathname: PAGES.ROOT,
    //     search: "?" + convertObjectToSearchParamString(searchParams)
    //   });
    // } else {
    //   history.push({ pathname: PAGES.ROOT });
    // }
    // window.location.reload();
  }

  /**
   * @description React life cycle method
   */
  componentDidMount() {  
    if(window.innerWidth < 1300 && this.props.isLeftSidebarExpanded){
      setLeftSideBarActiveMenu(false)
    }
    this.isOnlineStatusActive = !!get(this.props,"clientInfo.onlineStatus.isActive");
    if(this.isOnlineStatusActive){
      this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.reset();
      this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.resume();
      document.addEventListener(
        "visibilitychange",
        this.handleVisibilityChange,
        false
      );  
    }      
    document.addEventListener('mousedown', this.toggleCommunicationModal);
    document.addEventListener('mousedown', this.toggleLeftSideBar);

  }
  componentWillUnmount() {    
    if(this.isOnlineStatusActive){
      document.removeEventListener(
        "visibilitychange",
        this.handleVisibilityChange,
        false
      );
    }
    document.removeEventListener('mousedown', this.toggleCommunicationModal);
    document.removeEventListener('mousedown', this.toggleLeftSideBar)
  }
  
  componentDidUpdate(prevProps,prevState) {
    
    this.isOnlineStatusActive = !!get(this.props,"clientInfo.onlineStatus.isActive");    
    if(get(this.props,"userDetail.lead.id") !== get(prevProps,'userDetail.lead.id')){
      this.setState({
        leadId: get(this.props,'userDetail.lead.id')
      })
      if(this.isOnlineStatusActive){
        if(!get(this.props,"userDetail.lead.id") && get(prevProps,'userDetail.lead.id')){
          this.setState({
            onlineStatus: "offline"
          })
        }else if(get(this.props,"userDetail.lead.id")){
          this.setState({
            onlineStatus: "online"
          })
        }
      }      
    }

    const { userDetail = {}} = this.props || {};
    const avatarUrl = userDetail?.user?.avatarUrl;
    if (
      (avatarUrl || this.state.profilePhotoFile) &&
      !this.state.profilePhotoUrl &&
      avatarUrl != this.avatarUrlRef.current
    ) {
      this.avatarUrlRef.current = avatarUrl;
      getFileNameFromBlob(
        this.state.profilePhotoFile || userDetail.user.avatarUrl,
        BLOB_NAMES.EERESOURCES,
        BLOB_FOLDERS.EMAIL_ATTACHMENTS,
        { isImage: true },
        (url) => {
          this.setState({
            ...this.state,
            profilePhotoUrl: url,
          });
        }
      );
    }

    !this.isWindowListenerAdded && this.isOnlineStatusActive && this.addWindowListener();
    if(this.isOnlineStatusActive && get(this.props,"clientInfo.onlineStatus.idleTime") !== get(prevProps,'clientInfo.onlineStatus.idleTime')){
      this.idleTimerTimeout =  get(this.props,"clientInfo.onlineStatus.idleTime") ? parseInt(get(this.props,"clientInfo.onlineStatus.idleTime")) : 1000 * 15 * 60;
    }
  }

  renderConfirmationPopup=(e)=> {
    const {formData,config,initialFormData,masterData} = this.props;
      let localFormData =
      formData && JSON.parse(JSON.stringify(formData));
      let isAnyValueUpdate = updateUserInfoFromFormData(
        config,
        localFormData,
        initialFormData,
        true,
        masterData
      );
      if (isAnyValueUpdate) {
        this.setState({
          isLeftConfirmationPopupOpen: true
        });
      } else {
        this.onLogoutClicked(e);
      }
    
  }

  toggleModal = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      isLeftConfirmationPopupOpen: !this.state.isLeftConfirmationPopupOpen,
    });
  };

  toggleProfileModal = event => {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.isShowProfileUpdatePopup) {
      this.setState({
        profilePhotoFileName: "",
        invalidProfileFile: false,
        profilePhotoFile: null,
        profilePhotoObject: null,
        invalidFileMessage: "",
        profilePhotoUrl : null
      });
    }
    this.setState({
      isShowProfileUpdatePopup: !this.state.isShowProfileUpdatePopup
    });
  };

  handleVisibilityChange = (e) => {
    if(this.isOnlineStatusActive){
      if (document["hidden"]) {
        this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.reset();
        this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.pause();
        this.onIdleHandler();
      } else if(document.visibilityState === "visible"){
        this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.reset();
        this.onlineStatusIdleTimerRef && this.onlineStatusIdleTimerRef.resume();
        this.onActiveHandler();
      }
    }    
  };


  handleProfileFileChange = ({ target: { files } }) => {
    const cancel = !files.length;
    if (cancel) return;

    let validation = this.isFileValid(files[0]);

    if (validation.valid) {
      let fileUrl = URL.createObjectURL(files[0]);
      this.setState({
        profilePhotoFileName: files[0].name,
        invalidProfileFile: false,
        profilePhotoFile: fileUrl,
        profilePhotoObject: files[0],
        profilePhotoUrl : fileUrl
      });
    } else {
      this.setState({
        profilePhotoFileName: "",
        invalidProfileFile: !validation.valid,
        invalidFileMessage: validation.message
      });
    }
  };

  isFileValid = file => {
    let { size, name } = file;
    size = size / 1000;
    let validation = { valid: true, message: "" };
    if (size > PHOTO_FILE_PARAMETERS.VALID_MAX_FILE_SIZE_IN_KB) {
      validation.valid = false;
      validation.message = getLang(
        "global.validationMessage.invalidPhotoFileSize"
      );
      return validation;
    }

    let validExtensions = PHOTO_FILE_PARAMETERS.VALID_FILE_TYPES;
    let fileExtension = name.substring(name.lastIndexOf(".")).toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      validation.valid = false;
      validation.message = getLang("global.validationMessage.invalidFileType");
      return validation;
    }

    if (
      !fileNameLengthValidate(name, PHOTO_FILE_PARAMETERS.VALID_FILENAME_LENGTH)
    ) {
      validation.valid = false;
      validation.message = getLang(
        "global.validationMessage.invalidFileNameLength"
      );
      return validation;
    }

    return validation;
  };

  onProfileUpload = e => {
    console.log("Sfjanvjanvka")
    let { profilePhotoObject } = this.state;
    let { userDetail, clientInfo } = this.props;
    if (profilePhotoObject) {
      let url =
        window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.uploadProfilePicture
          ? window.extraaedge.API_END_POINT.uploadProfilePicture
          : "/users/uploadProfilePicture";

      let userId = userDetail.user.id;
      let clientAlias = clientInfo.Info.alias;
      try{
        const newFile = new File(
          [profilePhotoObject],
          `${this.checkAndRenameFileByToken(
            `${profilePhotoObject.name && profilePhotoObject.name.substring(0, profilePhotoObject.name.lastIndexOf("."))}`
          )}${profilePhotoObject.name && profilePhotoObject.name.substring(profilePhotoObject.name.lastIndexOf("."))}`,
          { type: profilePhotoObject.type }
        )
        this.props.uploadProfilePicture(
          url,
          newFile,
          userId,
          clientAlias
        );
      }catch(e){
        this.props.uploadProfilePicture(
          url,
          profilePhotoObject,
          userId,
          clientAlias
        );
      }

      this.toggleProfileModal(e);
    } else {
      notify("Please select new photo", {
        type: ToastType.ERROR,
        autoClose: true
      });
    }
  };
  checkAndGetCounselorSpecificLebel=()=>{
    const {isCounselorAccessActive,counselorDetails,userDetail}=this.props;
    if(isCounselorAccessActive && !isNil(counselorDetails) && Object.keys(counselorDetails).length>0){
      let cName=[];
      let langLabel=getLang("loggedInAsCounselorLabel", "Logged in by");
      langLabel = langLabel ? langLabel.replace("$COUNSOLER_NAME$", "") : "";
      !!counselorDetails.firstName && cName.push(counselorDetails.firstName)
      !!counselorDetails.lastName && cName.push(counselorDetails.lastName)
      return (
        <div className="logged-in-info">
          <span className="logged-in-by">
            {langLabel}
          </span>
          <span className="counselor-name">{cName.join(" ")}</span>
        </div>
      )
    }
    return null;
  }

  onActiveHandler = (e) => {
    if(this.isOnlineStatusActive)
    {
      this.setState({
        onlineStatus: 'online'
      });
      this.callOnlineStatusAPI(true);
    }
  };

  onIdleHandler = (e) => {
    if(this.isOnlineStatusActive)
    {
      this.setState({
        onlineStatus: 'offline'
      });
      this.callOnlineStatusAPI(false);
    }
  };

  callOnlineStatusAPI = async (isLeadOnline) => {    
    this.isOnlineStatusActive = !!get(this.props,"clientInfo.onlineStatus.isActive");
    const isCountinuePage = window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE; 
    const conditionToCallAPI =
      !isCountinuePage &&
      this.isOnlineStatusActive &&
      this.state.leadId &&
      !this.props.isCounselorAccessActive &&
      checkIfObject(this.props.userDetail); 
    if(conditionToCallAPI){
      try{
        let { clientInfo,userDetail:userDataDetail,masterData:masterDataDetails, updateIndexMetaDataInAllApplication } = this.props;   
        let clientAlias = get(clientInfo,`Info.alias`);    
        let indexedKeyValueAttributes = get(this.props,`userDetail.metaData.indexedKeyValueAttributes`);
        const masterKeysCollection = !isNil(masterDataDetails) && getMasterDataByKey(`masterKeys`,masterDataDetails);
        const onlineMasterKey=find(masterKeysCollection,{name:LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,isActive:true});
        const leadId = get(this.props.userDetail,`lead.id`);
        const metaData = find(indexedKeyValueAttributes,item=>{return item.keyId===onlineMasterKey.id;});
        const rowId = get(metaData,`id`) || 0;
        const multipleLeadEvents = getIsLeadOnlineAPIParams(this.props.allApplicationsDataMappedWithLeadIds, onlineMasterKey)
        if(onlineMasterKey){
          try{
            const requestBody={
              "LeadId": leadId,
              "Name": LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,
              "type": "bool",
              "value": !!isLeadOnline,
              "eventType": metaData ? "update" : "add",
              "id": checkIfArray(multipleLeadEvents) ? rowId : rowId || 0,
              "MultipleLeadEvents": multipleLeadEvents
            };
            const request = await Services.requestAddLeadEvent({...requestBody}); 
            if((request.status=== 200 || request.status === 201) && !isNil(get(request,`data.result`))){
              if(!indexedKeyValueAttributes && !Array.isArray(indexedKeyValueAttributes)){
                indexedKeyValueAttributes=[];
              }
              if (
                requestBody.eventType === "add" &&
                Array.isArray(multipleLeadEvents) &&
                multipleLeadEvents.length <= 1
              ) {
                indexedKeyValueAttributes.push(get(request, `data.result`));
              } else {
                if (indexedKeyValueAttributes.length > 0) {
                  indexedKeyValueAttributes = indexedKeyValueAttributes.map(
                    (obj) => {
                      if (obj.id === rowId) {
                        return {
                          ...obj,
                          boolValue: isLeadOnline,
                        };
                      }
                      return obj;
                    }
                  );
                }
              }
              set(userDataDetail,`metaData.indexedKeyValueAttributes`,indexedKeyValueAttributes);
              updateIndexMetaDataInAllApplication({
                indexedKeyValueAttributes,
                updatedUserDetail: userDataDetail,
                leadId
              })
            }
            leadObjectManager.init(
              userDataDetail,
              Object.assign({}, masterDataDetails)
            );
          }catch(e){
            console.log(e);
          }
        }
      }catch(e){
        throw new Error(e);
      }
    }
  };

  callSyncOnlineStatusAPI = async (isLeadOnline = false) => {    
    this.isOnlineStatusActive = !!get(this.props,"clientInfo.onlineStatus.isActive");
    const isCountinuePage = window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE; 
    const conditionToCallAPI =
      !isCountinuePage &&
      this.isOnlineStatusActive &&
      !this.props.isCounselorAccessActive &&
      checkIfObject(this.props.userDetail);
    if(conditionToCallAPI){
      try {
        const endpoint = window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.addOrEditEvent
          ? window.extraaedge.API_END_POINT.addOrEditEvent
          : "/leads/addOrEditEvent"
        const url = `${window.extraaedge.API_END_POINT.BASE_URL}${endpoint}`;
        let indexedKeyValueAttributes = get(this.props,`userDetail.metaData.indexedKeyValueAttributes`);
        const {masterData} = this.props;
        const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`masterKeys`,masterData);
        const onlineMaster=find(masterKeysCollection,{name:LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,isActive:true});
        const metaData = find(indexedKeyValueAttributes,item=>{return item.keyId===onlineMaster.id;});
        const rowId = get(metaData,`id`) || 0;
        const multipleLeadEvents = getIsLeadOnlineAPIParams(this.props.allApplicationsDataMappedWithLeadIds, onlineMaster)
        const body = {
          "LeadId": get(this.props.userDetail,`lead.id`),
          "Name": LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,
          "type": "bool",
          "value": !!isLeadOnline,
          "eventType": metaData ?"update":"add",
          "id": checkIfArray(multipleLeadEvents) ? rowId : rowId || 0,
          "multipleLeadEvents": multipleLeadEvents,
        }
        let securityKey = `UNABLE_TO_GENERATE`;
        try {
          const signature = generateSignature(endpoint,"",JSON.stringify(body));
          if(signature){
            securityKey = `${signature}`;        
          }
        } catch (error) {    
          securityKey = `UNABLE_TO_GENERATE`;
        }
        const rawResponse = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization:
              "Bearer " +
              OfflineStorage.getItem(
                STORAGE_KEY.ACCESS_TOKEN,
                STORAGE_TYPE.LOCAL
              ),
            "Access-Control-Allow-Origin": "*",
            "x-er-id": securityKey,
          },
          body: JSON.stringify(body),
          keepalive: true,
        });
        const content = await rawResponse.json();
        this.setState({
          onlineStatus: isLeadOnline ? "online" : "offline",
        });
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  checkAndRenameFileByToken = (fileName) => {
    const prependFileNameToken = !!get(this.props,`dashboardConfig.avatarURLToken.prependFileNameToken`)
      ? this.props.dashboardConfig.avatarURLToken.prependFileNameToken
      : !!get(this.props,`config.defaultProps.defaultAttributesByInputType.file.avatarURLToken.prependFileNameToken`)
      ? this.props.config.defaultProps.defaultAttributesByInputType.file.avatarURLToken.prependFileNameToken
      : null;
    const renameFileWithAvatarToken = !!get(this.props,`dashboardConfig.avatarURLToken.renameFileWithTokenBeforeUpload`)
      ? this.props.dashboardConfig.avatarURLToken.renameFileWithTokenBeforeUpload
      : !!get(this.props,`config.defaultProps.defaultAttributesByInputType.file.avatarURLToken.renameFileWithTokenBeforeUpload`)
      ? this.props.config.defaultProps.defaultAttributesByInputType.file.avatarURLToken.renameFileWithTokenBeforeUpload
      : null;
    if (prependFileNameToken && !isNil(prependFileNameToken) && !isEmpty(prependFileNameToken) && fileName.indexOf(prependFileNameToken) === -1 && this.props.userDetail && this.props.masterData) {
        let prependFileNameTokenValue = renameFileByToken(
          prependFileNameToken,
          this.props.userDetail,
          this.props.masterData
        );
        
        prependFileNameTokenValue = prependFileNameTokenValue && replaceSpecialCharacter(prependFileNameTokenValue);
        fileName = prependFileNameTokenValue ? `${prependFileNameTokenValue}_${fileName}` : fileName;
    }else if (renameFileWithAvatarToken && this.props.userDetail && this.props.masterData){
      let renameFileWithAvatarTokenValue = renameFileByToken(
        renameFileWithAvatarToken,
        this.props.userDetail,
        this.props.masterData
      );
      renameFileWithAvatarTokenValue = renameFileWithAvatarTokenValue && replaceSpecialCharacter(renameFileWithAvatarTokenValue);
      fileName = renameFileWithAvatarTokenValue || fileName;
    }
    return fileName;
  };

  showCommunicationPopup = (e) => {
    e.stopPropagation();
    this.setState({
      showCommunicationPopup: true
    })
  }

  toggleCommunicationModal = (event) => {
    // Check if the click occurred outside the Communication popup
    if (
      this.state.showCommunicationPopup &&
      this.communicationPopupRef &&
      !this.isPreviewTemplateModalOpen.current &&
      !this.communicationPopupRef.contains(event.target) 
    ) {
      // Click occurred outside, so close the Communication popup
      console.log("Communications EventClicked", this.communicationPopupRef.current)
      this.setState({ showCommunicationPopup: false });
    }
  }
  toggleLeftSideBar = (event) => {
    if (this.props.isLeftSidebarExpanded) {
      const parentDiv = event.target.closest(".right-content"); // returns null if element not found
      const headerDiv = event.target.closest(".left-side-bar");
      const sidebarDiv = event.target.closest(".sidebar-header");
      const chatBotContainer = event.target.closest(".chatBot-container");
      const sidebar = event.target.closest(".sidebar");
      const overlay = event.target.closest(".overlay");
      if ((parentDiv || overlay) || (!sidebarDiv && !sidebar && !chatBotContainer && headerDiv )) {
        this.props.setLeftSideBarActiveMenu(false);
      }
    }
  };

  redirectToDashboard = () => {
    this.props.setLeftNavigationActiveMenu("/"); 
    addURLParametersAndRoute(
      {
        pathname: PAGES.DASHBOARD,
        state: {from: this.props?.location?.pathname},
      },
      this.props.history
    );
  }

  render() {
    let {
      profilePhotoFileName,
      invalidProfileFile,
      profilePhotoFile,
      profilePhotoUrl,
      invalidFileMessage,
      leadId
    } = this.state;
    const {userDetail,isAutoSaveInProgress, domainConfig}=this.props;
    let loggedInUserInfo = userDetail;
    let name = loggedInUserInfo && loggedInUserInfo.user && !!loggedInUserInfo.user.firstName
        ? [loggedInUserInfo.user.firstName]
        : [];
    loggedInUserInfo && loggedInUserInfo.user && !!loggedInUserInfo.user.lastName && name.push(loggedInUserInfo.user.lastName);
    name = !!name ? name.join(" ") : "No name found";
    let logoURL = isLangExist("AppLogo")
      ? findAndReplaceInString(t("AppLogo"), [
          {
            find: "$BLOB_STORAGE_URL$",
            replace: window.extraaedge.client.Blob_Storage_Client_URL
          }
        ])
      : "";
    let mobilelogoURL = isLangExist("AppLogoMobile")
      ? findAndReplaceInString(t("AppLogoMobile"), [
          {
            find: "$BLOB_STORAGE_URL$",
            replace: window.extraaedge.client.Blob_Storage_Client_URL
          }
        ])
      : "";
    const hideHeaderActions = window.location.pathname === PAGES.LOGIN ||
    window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE
    return (
      <Navbar
        expand="md"
        className={`col-md-12 header flex-nowrap ${this.props.isLeftSidebarExpanded && window.innerWidth < 1300 ? "header-background" : ""}`}
      > {this.isOnlineStatusActive && leadId &&
            <IdleTimer
              ref={(ref) => {
                this.onlineStatusIdleTimerRef = ref;
              }}
              timeout={this.idleTimerTimeout}
              onActive={this.onActiveHandler}
              onIdle={this.onIdleHandler}
              debounce={10}
            />
        }
        {
          !(
            hideHeaderActions
          ) && (
            <Row className="ham-burger-icon"><img src={HamBurger} alt="" onClick={() => this.props.setLeftSideBarActiveMenu(true)}/></Row>
          )
        }
          {this.props.isLeftSidebarExpanded &&  window.innerWidth < 1300 &&   
               <Suspense fallback={<SuspendRoute />}>
               {( 
                <>
                 <Col
                   key={"left-content"}
                   className={`sidebar-col dashbaord left-side-bar mh-100 pl-0 pr-sm-0 ${window.innerWidth < 1300 ? "no-negative-margins" : ""} ${this.props.isLeftSidebarExpanded ? "left-sidebar-animation": "" }`}
                   style={{position:"absolute", backgroundColor:"unset"}}
                 >
                   {/** Render side bar */}
                   <div className="sidebar-header" style={{"width":"30%"}}>
                      <img className="arrowBack-img" src={ArrowBack} alt="" onClick={() => this.props.setLeftSideBarActiveMenu(false)}/>
                   </div>
                   <LazyMenuNavigationComponent
                    domainConfig={this.props.domainConfig} 
                    history={this.props.history}
                    />
                 </Col>
                </>
               )}
                </Suspense>
            }
     
        <NavbarBrand className="col-sm-2" 
          style={this.props.isLeftSidebarExpanded && window.innerWidth < 1300 ? {"opacity":"0"} : {}}>
          {isLangExist("AppLogo") && (
            <img
              className="appLogo d-none d-sm-inline"
              src={!!logoURL ? logoURL : undefined}
              alt={t("AppName")}
              loading="lazy"
              onClick={this.redirectToDashboard}
            />
          )}
          {isLangExist("AppLogoMobile") && (
            <img
              className="appLogo d-sm-none"
              src={!!mobilelogoURL ? mobilelogoURL : undefined}
              alt={t("AppName")}
              loading="lazy"
              onClick={this.redirectToDashboard}
            />
          )}
        </NavbarBrand>
        <span className="appform-name">{getLang(t("AppName"))}</span>
        {loggedInUserInfo && [
          <Nav className="ml-auto right-side-header flex-nowrap" key={"appform-name"}
          style={this.props.isLeftSidebarExpanded && window.innerWidth < 1300 ? {"opacity":"0"} : {}}>
            {isAutoSaveInProgress && (
              <div className="auto-save">{getLang("autoSaveText")}</div>
            )}
            {
              getValue(domainConfig, "header.query.isActive") && !hideHeaderActions && (
                <div className="ask-quey-header-btn">{getLang("header.query.btnTitle", "Ask a query")}</div>
              )
            }
            {getValue(domainConfig, "header.communication.isActive") && !(hideHeaderActions) && (
              <div
                className="communication-header-icon"
                onClick={this.showCommunicationPopup}
                title="Communication"
              >
                <img src={CommunicationHeaderIcon} alt=""/>
                <span className="total-communication-count"></span>
              </div>
            )}
            {this.checkAndGetCounselorSpecificLebel()}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="btnMenuToggle">
                <ProfileImageWithLoader
                  imageClassName="avatar"
                  src={profilePhotoFile || this.state.profilePhotoUrl}
                  alt={name}
                  showLoader={false}
                />
              </DropdownToggle>
              <DropdownMenu right>
                {!!getLang("form.controls.updateProfile.label", "") ? (
                  <DropdownItem onClick={this.toggleProfileModal}>
                    {getLang("form.controls.updateProfile.label")}
                  </DropdownItem>
                ) : null}
                {this.props.clientInfo &&
                this.props.clientInfo.links &&
                this.props.clientInfo.links.faq &&
                !!this.props.clientInfo.links.faq.visible ? (
                  <DropdownItem
                    tabIndex="0"
                    role="menuitem"
                    dangerouslySetInnerHTML={{
                      __html: getLang(this.props.clientInfo.links.faq.text),
                    }}
                    className="dropdown-item ee-link text-dark dropdown-item"
                  ></DropdownItem>
                ) : null}
                <DropdownItem onClick={(e) => this.renderConfirmationPopup(e)}>
                  {getLang("global.captions.logout")}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>,
        ]}
        {/* {this.state.isLeftConfirmationPopupOpen &&this.renderConfirmationPopup()} */}
        {this.state.isLeftConfirmationPopupOpen && (
          <React.Fragment>
            <div>
              <Modal centered={true} isOpen={true} toggle={this.toggleModal}>
                <ModalHeader toggle={this.toggleModal}>
                  {getLang("global.leftConfirmation.heading")}
                </ModalHeader>
                <ModalBody>
                  {getLang("global.leftConfirmation.message")}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={this.onLogoutClicked}>
                    {" "}
                    {getLang("global.leftConfirmation.yesLabel")}
                  </Button>{" "}
                  <Button color="secondary" onClick={this.toggleModal}>
                    {" "}
                    {getLang("global.leftConfirmation.noLabel")}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </React.Fragment>
        )}
        {this.state.isShowProfileUpdatePopup && (
          <React.Fragment>
            <div>
              <Modal className="profile-modal-content" isOpen={true} toggle={this.toggleProfileModal}>
                <ModalHeader className="profile-modal-header" toggle={this.toggleProfileModal}>
                  <span>{getLang("global.uploadProfilePhotoModal.title")}</span>
                </ModalHeader>
                <ModalBody>
                  <div className="profile-modal-body">
                    <ProfileImageWithLoader
                      src={profilePhotoUrl}
                      alt="Profile"
                      imageClassName="profile-photo"
                      showLoader={false}
                      loading="lazy"
                    />
                    <FormGroup>
                      <Label for="profilePhoto" className="select-label">
                        {getLang("global.uploadProfilePhotoModal.labelSelect")}
                      </Label>
                      <CustomInput
                        style={{cursor:"pointer"}}
                        type="file"
                        name="file"
                        id="profilePhoto"
                        label={profilePhotoFileName || "Select image"}
                        accept="image/x-png,image/gif,image/jpeg"
                        invalid={invalidProfileFile}
                        onChange={this.handleProfileFileChange}
                      />
                      {invalidProfileFile ? (
                        <FormText color="danger">{invalidFileMessage}</FormText>
                      ) : null}
                      <FormText color="muted" className="description">
                        {getLang("global.uploadProfilePhotoModal.description")}
                      </FormText>
                    </FormGroup>
                  </div>
                </ModalBody>
                <ModalFooter>
                <Button
                  style={{backgroundColor:"white",border: "1px solid #D8D8D8",borderRadius:"6px"}}
                  color="secondary" onClick={this.toggleProfileModal}>
                    {getLang(
                      "global.uploadProfilePhotoModal.buttonLabelCancel"
                    )}
                  </Button>
                  <Button
                    color="primary"
                    className={
                      invalidProfileFile || !profilePhotoFileName
                        ? "disabled"
                        : ""
                    }
                    onClick={this.onProfileUpload}
                    disabled={invalidProfileFile || !profilePhotoFileName}
                  >
                    {getLang(
                      "global.uploadProfilePhotoModal.buttonLabelUpload"
                    )}
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </React.Fragment>
        )}
        {this.state.showCommunicationPopup && (
          <div className="communication-ref" ref={(node) => (this.communicationPopupRef = node)}>
            <Suspense fallback={<></>}>
              <LazyCommunication
                communicationConfig={getValue(
                  domainConfig,
                  "header.communication"
                )}
                updateIsPreviewTemplateModalRef={this.updateIsPreviewTemplateModalRef}
              />
            </Suspense>
          </div>
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  domainConfig: state.appDataReducer && state.appDataReducer.domainConfig,
  rootState: state.rootState,
  loginReducer: state.loginReducer,
  counselorDetails: state.appDataReducer && state.appDataReducer.counselorDetails,
  masterData: state.appDataReducer && state.appDataReducer.masterData,
  isCounselorAccessActive: state.appDataReducer && state.appDataReducer.isCounselorAccessActive,
  userDetail: state.appDataReducer && state.appDataReducer.userDetail,
  clientInfo: state.appDataReducer && state.appDataReducer.clientInfo,
  config: state.formReducer && state.formReducer.data,
  formData: state.formReducer && state.formReducer.formData,
  initialFormData: state.formReducer && state.formReducer.initialFormData,
  isAutoSaveInProgress: state.appDataReducer && state.appDataReducer.isAutoSaveInProgress,
  isLeftSidebarExpanded:state.appDataReducer && state.appDataReducer.isLeftSidebarExpanded,
  allApplicationsDataMappedWithLeadIds: get(state,`appDataReducer.allApplicationsDataMappedWithLeadIds`) || {}
});

const HeaderComponentConnected = withRouter(
  connect(mapStateToProps, {
    logout,
    uploadProfilePicture,
    userMetaDataUpdate,
    setLeftSideBarActiveMenu,
    setLeftNavigationActiveMenu,
    updateIndexMetaDataInAllApplication,
  })(Header)
);

export { HeaderComponentConnected as default };

