import { API, SLOTAPI } from "../../infrastructure/network/HttpClient";
export const Service = {
  getConfig: function(url) {
    //return API.get("/form/config");
    return fetch(url)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        return myJson;
      })
      .catch((error) => {
        // handle your errors here
        console.error(error);
        return null;
      });
  },
  uploadFile: function(params, fd) {
    return API.post(params, fd);
  },
  applyPromoCode: function(params, fd) {
    return API.post(params, fd);
  },
  callGetRequest: function(params, fd) {
    return API.get(params, fd);
  },
  callPostRequest: function(params, fd) {
    return API.post(params, fd);
  },
  getAllSlots: (params, fd) => {
    return API.get(params);
  },
  saveSlot: (params,fd) => {
    return API.post(params,fd)
  },
  fetchAllSlots: (params) => {
    return SLOTAPI.get(params);
  },
  bookSlot: (params, fd) => {
    return SLOTAPI.post(params, fd);
  },
  getBookedSlotIndexData: (params) => {
    return SLOTAPI.get(params);
  },
  initializePayment: (params,fd) => {
    return API.post(params,fd)
  },
  processPayment: (params,fd) => {
    return API.post(params,fd)
  },
  getPinCodes: (params,fd) => {
    return API.get(params,fd)
  },
  sendOtp: (params, fd) => {
    return API.post(params, fd);     
  },
  verifyOtp: (params, fd) => {
    return API.post(params, fd);
  },
  verifyIfEmailMobileExists: (params, fd) => {
    return API.get(params, fd)
  },
  verifyIfFieldValueExists: (params, fd) => {
    return API.get(params, fd)
  },
  // getConfig: function() {
  //   return API.get("/resources/form-config.json", {
  //     baseURL: "%PUBLIC_URL%"
  //   });
  // }
  initializeAdcbPayment: (params,fd) => {
    return API.post(params,fd)
  },
  initializeGrayQuestPayment: (params,fd) => {
    return API.post(params,fd)
  },
};
