import { cloneDeep, filter, find, forIn, get, has, isNil, isNumber, pickBy, reduce,  set, uniq } from "lodash";
import { ToastType } from "react-toastify";
import { all, call, delay, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import zipy from "zipyai";
import { renderApp } from "../../../index";
import { logError } from "../../../infrastructure/logging/Logger";
import { actionTypes as FormAction, updateFormConfigSuccess } from "../../../pages/form/Actions";
import { actionTypes as LoginAction } from "../../../pages/login/Actions";

import * as rdd from 'react-device-detect';
import { ACCESS_TOKEN_CLAIMS, ACCESS_TOKEN_LEAD_KEY, APPFORM_LEAD_SOURCEID_KEYS, CONNECTION_ERROR, EDIT_LEAD_API_CALL_FROM, EntityFieldNameByCriteriaValue, LEAD_EESOURCE_IDS, LEAD_EVENT_MASTER_KEYS, META_DATA_TYPE, MISSING_VALUES, PAGES, PAYMENT_METHODS_WITH_MODAL_ON_SAMEPAGE, PAYMENT_METHOD_ID, PAYMENT_METHOD_IDS_WHICH_HAS_DUPLICATE_REFRENCE_KEY, STEP_NAVIGATION_TYPE, STORAGE_KEY, STORAGE_TYPE } from "../../utilties/Constants";
import { OfflineStorage } from "../../utilties/OfflineStorage";
import {
  checkIfArray,
  checkIfObject,
  ConvertToUTCUsingMoment, createAnchorTag, eeCustomAzureLogs, getEntityCollection, getLang, getMasterDataByKey, getMediaLiveURL, getUniqueKeyCriteriaValue, isAccessTokenTableKeyEnabled, isArrayValueInSequence, isEmpty,
  leadObjectManager, logoutApplicationForm, metaDataUpdate, notify,
  renameAllProprtyNamebyFirstLetterLowerCase,
  replaceTokenOfPDFAPIRequest, updateFormDataFromUserInfo,
  getIsLeadOnlineAPIParams,
  validateMappedLeadStatus,
  getValueFromAccessTokenByKey,
} from "../../utilties/Utils";
import { appInsights, IS_APPINSIGHT_ACTIVE } from "../appInsights";
import {
  actionTypes,
  addLeadEventFail,
  addLeadEventRequested,
  addLeadEventSuccess,
  authDetailsFail,
  authDetailsRequested,
  authDetailsSuccess,
  clientInfoFail,
  clientInfoRequested,
  clientInfoSuccess,
  counselorDetailFailed,
  counselorDetailRequested,
  counselorDetailSuccess,
  domainConfigFail,
  domainConfigRequested,
  domainConfigSuccess,
  generatePDFFail,
  generatePDFRequested,
  generatePDFSuccess,
  generatePreviewPDFFail,
  generatePreviewPDFRequested,
  generatePreviewPDFSuccess,
  generateTemplatePDFFail,
  generateTemplatePDFRequested,
  generateTemplatePDFSuccess,
  getActiviesFail,
  getActiviesRequested,
  getActiviesSuccess,
  getAllApplicationFail,
  getAllApplicationRequested,
  getAllApplicationSuccess,
  getAllApplicationsConfigFail,
  getAllApplicationsConfigInit,
  getAllApplicationsConfigSuccess,
  getLeadDocumentsFail,
  getLeadDocumentsRequested,
  getLeadDocumentsSuccess,
  getValuesFromHistoryFail,
  getValuesFromHistoryRequested,
  getValuesFromHistorySuccess,
  logout,
  masterDataCompleted,
  masterDataFail,
  masterDataInit,
  masterDataRequested, paymentTransactionHistoryFailed, paymentTransactionHistoryRequested,
  paymentTransactionHistorySuccess, pushAppformLeadFail,
  pushAppformLeadRequested, setApplicationCardData, setAutoNavigateRuote, setLeadHistoryStatusId, swapCourseEditLeadFailed,
  setLeadIdWiseHistoryValuesApiNotCalled,
  swapCourseEditLeadRequested,
  swapCourseEditLeadSuccess,
  updateApplicationsAuthDetailsAccordingToLeadId,
  updateClientInfo,
  updateCurrentStep,
  uploadProfilePictureFailed,
  uploadProfilePictureRequested,
  uploadProfilePictureSuccess,
  userDetailFailed,
  userDetailRequested,
  userDetailSuccess,
  userDetailUpdateFailed,
  userDetailUpdateRequested,
  userDetailUpdateSuccess,
  userMetaDataUpdateFailed,
  userMetaDataUpdateRequested,
  userMetaDataUpdateSuccess,
  toggleGlobalLoader,
  updateIndexMetaDataInAllApplicationSuccess,
  updateAllConfigurationsAccordingToInst
} from "./Actions";
import { Services } from "./Services";
import { configSuccess } from "../../../pages/dashboard/Actions";
import { updateFormDataAccordingToInst } from "../../../pages/form/Saga";
import { Configuration } from "../../../infrastructure/configuration/appsettings";
import i18n from "../../../infrastructure/localization/localizationservice";
const jwt_decode = require("jwt-decode");

export const getAppDataReducer = state => state.appDataReducer;
export const getAllMasterData = state => state.appDataReducer.masterData;
export const getuserDetailData = state => state.appDataReducer.userDetail;
export const getFormData = state => state.formReducer.formData;
export const getFormConfigURL = state => state.formReducer.formConfigURL;
export const getFormReducer = state => state.formReducer;
export const getInitialFormData = state => state.formReducer.initialFormData;
export const getClientInfoData = state => state.appDataReducer.clientInfo;
export const getLeadHistoryStatusId = state => state.appDataReducer.leadHistoryStatusId;
export const getDomainConfigData = state => state.appDataReducer.domainConfig;
export const getActiviesData = state => state.appDataReducer.studentActivities;
export const getAllApplicationData = state => state.appDataReducer.allApplicationsData;
export const getApplicationAuthDetailsMappedWithLeadId = (state) =>
  state.appDataReducer.applicationsAuthDetailsMappedWithLeadId;
export const getMasterDataReducer = state => state.appDataReducer.masterData;
export const getIsCounselorAccessActive = state => state.appDataReducer.isCounselorAccessActive
export const getCounselorDetails = (state) => state.appDataReducer.counselorDetails;


export function* getAllMaster(action) {
  //CAll api
  try {
    yield put(masterDataRequested(""));
    const masterData = yield select(getAllMasterData); // <-- get reducer data
    const clientInfo = yield select(getClientInfoData); // <-- get reducer data

    if (masterData && Object.keys(masterData).length) {
      yield put(masterDataCompleted(masterData));
    } else if(clientInfo) {
      const [resultMasterData, resultMasterSetting, resultCustomMasterData] = yield all([
        call(Services.getAllMaster, clientInfo),
        call(Services.getStoreSetting, clientInfo),
        call(Services.getCustomMaster, clientInfo)
      ]);
      if (
        (resultMasterData.status === 200 &&
          resultMasterSetting.status === 200) ||
        (resultMasterData.status === 201 && resultMasterSetting.status === 201)
      ) {
        let renamedProprtyMasterData = !!resultMasterData.isMasterDataFromJSON
          ? renameAllProprtyNamebyFirstLetterLowerCase(resultMasterData.data)
          : resultMasterData.data;
        renamedProprtyMasterData = Object.assign(renamedProprtyMasterData, 
          resultCustomMasterData?.data?.masterData);
        window.extraaedge &&
          (window.extraaedge.masterData = Object.assign(
            {},
            renamedProprtyMasterData,
            resultMasterSetting.data
          ));
        const accessTokenTableKey = get(resultMasterSetting, "data.accessTokenTableKey");
        if(!!accessTokenTableKey) {
          window.extraaedge.accessTokenTableKey = accessTokenTableKey;
        }
        if (clientInfo && !!clientInfo.isValidateMappedLeadStatus) {
          validateMappedLeadStatus(clientInfo, renamedProprtyMasterData);
        }
        yield put(
          masterDataCompleted(
            Object.assign(
              {},
              renamedProprtyMasterData,
              resultMasterSetting.data
            )
          )
        );

        renderApp({masterDataLoaded:true});
        if (
          action &&
          action.payload &&
          action.payload.data &&
          !!action.payload.data.nextAction &&
          action.payload.data.payload
        ) {
          yield put({
            type: action.payload.data.nextAction,
            payload: action.payload.data.payload
          });
        }
      } else {
        throw new Error(resultMasterData);
      }
    }
  } catch (error) {
    logError(error);
    notify(getLang("global.notification.masterDataFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });
    yield put(masterDataFail(error));
  }
}

export function* validateLogin(action) {
  //CAll api
  try {
    yield put(authDetailsRequested(""));
    const logFrom = get(action,`payload.loginInfo.logFrom`) || "appData-saga-validateLogin";
    const result = yield call(Services.validateLogin, action.payload.loginInfo);
    const inst = result && result.data && result.data.inst;
    result && result.data && OfflineStorage.setItem( STORAGE_KEY.ACCESS_TOKEN, result.data.accesstoken, STORAGE_TYPE.LOCAL );    
    const isAccessTokenTableKeyEnabledValue = isAccessTokenTableKeyEnabled();
    if(!isAccessTokenTableKeyEnabledValue){
      //when security featue ie OFF then add UKC (if found in token) and PRN in localStorage
      const ukc = getValueFromAccessTokenByKey(ACCESS_TOKEN_CLAIMS[STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]);
      const prn = getValueFromAccessTokenByKey(ACCESS_TOKEN_CLAIMS[STORAGE_KEY.PRN]);
      if(!isNil(ukc)){
        OfflineStorage.setItem( STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, ukc, STORAGE_TYPE.LOCAL );
      }
      if(!isNil(prn)){
        OfflineStorage.setItem( STORAGE_KEY.PRN, prn, STORAGE_TYPE.LOCAL );
      }
    }    
    const storage_inst = !isAccessTokenTableKeyEnabledValue ? OfflineStorage.getItem( STORAGE_KEY.INST, STORAGE_TYPE.LOCAL ) : OfflineStorage.getItem( STORAGE_KEY.VERIFIED_INST, STORAGE_TYPE.LOCAL );
    if(inst && inst!==storage_inst){
      if(isAccessTokenTableKeyEnabledValue){
        OfflineStorage.setItem( STORAGE_KEY.VERIFIED_INST, inst, STORAGE_TYPE.LOCAL );
      }else{
        OfflineStorage.setItem( STORAGE_KEY.INST, inst, STORAGE_TYPE.LOCAL );
      }
      let instwiseConfigs = {};
      const config = yield call(fetchConfigurationsForInst, inst);
      instwiseConfigs[inst] = config;
      yield put(getAllApplicationsConfigSuccess(instwiseConfigs));
      yield put(updateAllConfigurationsAccordingToInst(config));      
    }else if(!inst){
      OfflineStorage.deleteItem( STORAGE_KEY.VERIFIED_INST, STORAGE_TYPE.LOCAL );
      OfflineStorage.deleteItem( STORAGE_KEY.INST, STORAGE_TYPE.LOCAL );
    }
    
    result && result.data && (yield put(authDetailsSuccess(result.data)));

    yield put({ type: actionTypes.USER_DETAIL_INIT,payload: {logFrom} });

    const accessToken = get(result, "data.accesstoken") || ""
    if(accessToken) {
      const tokenDecoded = jwt_decode(accessToken);  
      const leadId = tokenDecoded[ACCESS_TOKEN_LEAD_KEY];
      if(leadId) {
        yield put(updateApplicationsAuthDetailsAccordingToLeadId({
          [leadId]: accessToken
        }));
      }
    }
  } catch (error) {
    logError(error);
    yield put({
      type: LoginAction.UPDATE_SERVER_ERROR,
      payload: { error }
    });
    yield put(authDetailsFail(error));
  }
}

export function* updateUserInfoData(action) {
  //CAll api
  let isAPIRespondedSuccess=false;  
  const appDataReducer = yield select(getAppDataReducer); 
  const formReducer = yield select(getFormReducer);
  const formConfigUrlReducer = yield select(getFormConfigURL)
  const masterData = yield select(getAllMasterData); // <-- get reducer data
  const leadHistoryStatusId = yield select(getLeadHistoryStatusId);
  const userDetail= cloneDeep(action?.payload?.data);
  const clientInfo = yield select(getClientInfoData); // <-- get reducer data
  const currentStep = appDataReducer.currentStep || OfflineStorage.getItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,STORAGE_TYPE.LOCAL);
  const currentStep_reducer = appDataReducer.currentStep;
  const currentStep_ls = OfflineStorage.getItem(`${STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP}`,STORAGE_TYPE.LOCAL);
  const currentStepIndex=action?.payload?.nextStepIndex - 1;
  const logFrom = get(action,`payload.logFrom`);
  if(logFrom===EDIT_LEAD_API_CALL_FROM.DEBID_DETAIL_UPDATE){
    set(userDetail, 'isSaveAsDraft', true)
  }
  const CLIENT_ALIAS =
    !isNil(clientInfo) && !isNil(get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`)) 
        ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
        : "";
  const leadId=get(action,`payload.data.lead.id`);
  let result=null;
  let appFormStepStatusSequences = getMasterDataByKey(`appFormStepStatusSequences`,masterData);
  appFormStepStatusSequences  = checkIfArray(appFormStepStatusSequences) ? appFormStepStatusSequences.join('').match(/\d+/g).join(',') : "";
  try {
    
    if(checkIfArray(action?.payload?.formData?.enrolledLeads)){
      set(userDetail, 'enrolledLeads', action.payload.formData.enrolledLeads)
    }
    let newAppformStepIndex=null
    if(!!action?.payload?.formData && !!formReducer?.data && userDetail) {
      const completionPercentage = !!action?.payload?.completionPercentage && Math.ceil(action?.payload?.completionPercentage);
      get(formReducer, `data.completionPercentage.isActive`) && completionPercentage && metaDataUpdate(masterData, userDetail, META_DATA_TYPE.INDEX_META_TYPE, STORAGE_KEY.APPFORM_COMPLETION_PERCENTAGE, completionPercentage)
    }   
    if(!!action?.payload?.formData && !!formReducer?.data && !!action?.payload?.nextStepIndex && isNumber(action?.payload?.nextStepIndex)){
      const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`masterKeys`,masterData);
      const appfromCompletedStepIndexMasterKeyConfig=checkIfArray(masterKeysCollection) ? find(masterKeysCollection,item=>{return item.name===STORAGE_KEY.APPFORM_NAVIGATION_STATUS;}):null;
      let keyValueMetaData = get(userDetail,`metaData.${META_DATA_TYPE.NON_INDEX_META_TYPE}`);
      if(isNil(keyValueMetaData) || !Array.isArray(keyValueMetaData)){
        keyValueMetaData=[];
      }
      if(!!appfromCompletedStepIndexMasterKeyConfig && !!keyValueMetaData && Array.isArray(keyValueMetaData)){
        let appfromCompletedStepIndexMetaData=find(keyValueMetaData,{keyId:appfromCompletedStepIndexMasterKeyConfig.id});
        if(isNil(appfromCompletedStepIndexMetaData)){
          appfromCompletedStepIndexMetaData={
            keyId: appfromCompletedStepIndexMasterKeyConfig.id,
            leadId: userDetail.lead.id,
            value: JSON.stringify({[STORAGE_KEY.APPFORM_COMPLETED_STEP_INDEX]:[0]}),
            id: 0
          };
        }
        if(!!appfromCompletedStepIndexMetaData?.value){
          let appfromCompletedStepIndexMetaDataValue=JSON.parse(appfromCompletedStepIndexMetaData.value);
          let currentAppfromCompletedStepIndex=get(appfromCompletedStepIndexMetaDataValue,STORAGE_KEY.APPFORM_COMPLETED_STEP_INDEX);
          const formConfig=formReducer?.data;
          if(!!formConfig?.steps && Array.isArray(formConfig?.steps) && (currentStepIndex)<=formConfig.steps.length){
            if (!currentAppfromCompletedStepIndex.includes(currentStepIndex) && isNil(currentAppfromCompletedStepIndex.find(function(val){
              if(currentStepIndex<=val){return true}}))) {
              currentAppfromCompletedStepIndex.push(currentStepIndex);
              currentAppfromCompletedStepIndex.sort();
              if (get(window,`extraaedge.showForceRefreshPopup.isActive`) && !isArrayValueInSequence(currentAppfromCompletedStepIndex) ) {
                try {
                  CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:'editAppForm_INCORRECT_STEP_INDEX',"appfromCompletedStepIndex":currentAppfromCompletedStepIndex,logFrom,currentStep,deviceDetect:rdd.deviceDetect(), formConfigURL:formConfigUrlReducer });
                } catch (error) {
                  
                }
                IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppForm_INCORRECT_STEP_INDEX_${leadId}`, properties: { "appfromCompletedStepIndex":currentAppfromCompletedStepIndex,logFrom,currentStep } });
                yield put({ type: actionTypes.SHOW_REFRESH_WINDOW_POPUP });
                return;
              }
              set(appfromCompletedStepIndexMetaDataValue,STORAGE_KEY.APPFORM_COMPLETED_STEP_INDEX,currentAppfromCompletedStepIndex);
              newAppformStepIndex=currentAppfromCompletedStepIndex;
              set(appfromCompletedStepIndexMetaData,`value`,JSON.stringify(appfromCompletedStepIndexMetaDataValue));
              if(appfromCompletedStepIndexMetaData.id===0){
                keyValueMetaData.push(appfromCompletedStepIndexMetaData);
                set(userDetail,`metaData.${META_DATA_TYPE.NON_INDEX_META_TYPE}`,keyValueMetaData);
              }else{
                keyValueMetaData=reduce(keyValueMetaData,function(result,val){if(val.keyId===appfromCompletedStepIndexMasterKeyConfig.id){result.push(appfromCompletedStepIndexMetaData)}else{result.push(val)}   return result},[]);
              }
            }
          }
        }          
      }
    }
    yield put(userDetailUpdateRequested(action.payload.isAutoSaveRequest));
    try {
      CLIENT_ALIAS &&
        leadId &&
        eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
          method: "editAppFormReq",
          data: userDetail,
          logFrom,
          currentStep,
          currentStep_reducer,
          currentStep_ls,
          currentStepIndex,
          deviceDetect: rdd.deviceDetect(),
          formConfigURL: formConfigUrlReducer,
        });
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormReq_${leadId}`, properties: { data:action.payload.data,logFrom,currentStep} })
    result = yield call(Services.updateUserDetail, userDetail, masterData);    
    if (result.status === 200 || result.status === 201) {
      //Pradip
    const statusId = get(result,`data.lead.statusId`);
    if(!!statusId){
      let newLeadHistoryStatusId = !!leadHistoryStatusId && Array.isArray(leadHistoryStatusId)?leadHistoryStatusId:[];
      if(newLeadHistoryStatusId.indexOf(statusId)===-1){
        newLeadHistoryStatusId.push(statusId);
        try{
          if(newLeadHistoryStatusId.length>0)
          yield put(setLeadHistoryStatusId(newLeadHistoryStatusId));
        }catch(err){
  
        }
      }
      yield put(setLeadIdWiseHistoryValuesApiNotCalled(leadId));
    }
    try {
      CLIENT_ALIAS &&
        leadId &&
        eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
          method: "editAppFormRes",
          data: !!result.data ? result.data : result,
          logFrom,
          currentStep,
          currentStep_reducer,
          currentStep_ls,
          deviceDetect: rdd.deviceDetect(),
          formConfigURL: formConfigUrlReducer,
        });
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormRes_${leadId}`, properties: { data:!!result.data?result.data:result,logFrom,currentStep } })
      yield put({ type: FormAction.LEAD_EDIT_ERROR_RESET, payload: null});
      isAPIRespondedSuccess=true;
      const paymentMethodId = formReducer?.formData?.paymentMethod && formReducer.formData.paymentMethod;
      const paymentMethodId_cf = formReducer?.formData?.paymentMethod_cf && formReducer.formData.paymentMethod_cf;
      const isPaymentModalOnSamePage =
        checkIfArray(paymentMethodId) && PAYMENT_METHODS_WITH_MODAL_ON_SAMEPAGE.includes(paymentMethodId[0]) ||
        checkIfArray(paymentMethodId_cf) && PAYMENT_METHODS_WITH_MODAL_ON_SAMEPAGE.includes(paymentMethodId_cf[0]);
      if(isPaymentModalOnSamePage){
        yield put({ type: actionTypes.GLOBAL_LOADER_TOGGLE, payload: {flag:false}});
      }
      result && result.data && (yield put(userDetailUpdateSuccess(result.data)));
      
      if (action.payload && action.payload.formData/* && !!action.payload.isAutoSaveRequest*/) {
        //update appform index and call
        if(!!newAppformStepIndex){
          set(action.payload.formData,STORAGE_KEY.APPFORM_COMPLETED_STEP_INDEX,newAppformStepIndex);
        }
        yield put({ type: FormAction.UPDATE_FORM_INITIAL_DATA, payload: { data: Object.assign({}, action.payload.formData) } });
      }
      if ( !isNil(action) && !isNil(get(action, "payload.generatePDFDetails")) && Object.keys(get(action, "payload.generatePDFDetails")).length ) {
        yield put({ type: actionTypes.GENERATE_PDF_INTI, payload: { data: get(action, "payload.generatePDFDetails") } });
      }
      if (
        !isNil(action) &&
        !isNil(get(action, "payload.generateTemplatePDFDetails")) &&
        Object.keys(get(action, "payload.generateTemplatePDFDetails")).length
      ) {
        yield put({
          type: actionTypes.GENERATE_TEMPLATE_PDF_INIT,
          payload: { data: get(action, "payload.generateTemplatePDFDetails") }
        });
      }
      try{
        if (action && action.payload && action.payload.callback) {
          action.payload.callback(
            true,
            action.payload && !!action.payload.nextStepIndex
              ? action.payload.nextStepIndex
              : null,
              action.payload
          );
        }
      }catch(err){
        console.log("CALLBACK METHOD CALLED ISSUE");        
        try {
          CLIENT_ALIAS &&
            leadId &&
            eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
              method: "editAppFormError 01",
              error: err,
              logFrom,
              currentStep,
              currentStep_reducer,
              currentStep_ls,
              deviceDetect: rdd.deviceDetect(),
              formConfigURL: formConfigUrlReducer,
            });
        } catch (error) {
          
        }
        IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormError_${leadId}`, properties: { error:err,logFrom,currentStep } });
      }
    } else {
      console.log("CALLBACK METHOD CALLED ISSUE");
      yield put({ type: actionTypes.GLOBAL_LOADER_TOGGLE, payload: {flag:false}});        
        try {
          CLIENT_ALIAS &&
            leadId &&
            eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
              method: "editAppFormError 02",
              error: result,
              logFrom,
              currentStep,
              currentStep_reducer,
              currentStep_ls,
              deviceDetect: rdd.deviceDetect(),
              formConfigURL: formConfigUrlReducer,
            });
        } catch (error) {
          
        }
      IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormError_${leadId}`, properties: { error:result,logFrom,currentStep } });
      throw new Error("Edit API failed");
    }
  } catch (error) {    
    console.error("error", error);
    console.log(`result`,result);
    yield put({ type: actionTypes.GLOBAL_LOADER_TOGGLE, payload: {flag:false}});
    try {
      CLIENT_ALIAS &&
        leadId &&
        eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
          method: "editAppFormError 03",
          error: error,
          logFrom,
          currentStep,
          currentStep_reducer,
          currentStep_ls,
          deviceDetect: rdd.deviceDetect(),
          formConfigURL: formConfigUrlReducer,
        });
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormError_${leadId}`, properties: { error:error,logFrom,currentStep } });
      if(!isAPIRespondedSuccess){
        logError(result);        
        const {leadEditErrorCount} = yield select(getFormReducer)
        const {apiConnectionErrorPopup,userdbrequired} = yield select(getClientInfoData);
        if(!isNil(result) && !!get(result,`data.message`) && (result.data.message).includes(MISSING_VALUES) && !isNil(userdbrequired) && Array.isArray(userdbrequired) && userdbrequired.length>0){
          yield put(setAutoNavigateRuote({stepIndex:1}))
          notify(getLang("global.notification.userDbRequired","Please check your all mandatory fields and try again."), {
            type: ToastType.ERROR,
            autoClose: true
          });
        }else if (isNil(apiConnectionErrorPopup) ||
          (!!apiConnectionErrorPopup.isActive &&
            (!isNil(apiConnectionErrorPopup.errorCheckCount) &&
            apiConnectionErrorPopup.errorCheckCount === 0) || (!isNil(CONNECTION_ERROR.errorCheckCount) && CONNECTION_ERROR.errorCheckCount === 0)) ||
          (apiConnectionErrorPopup.isActive && 
            (leadEditErrorCount < (apiConnectionErrorPopup.errorCheckCount - 1 || CONNECTION_ERROR.errorCheckCount - 1)))) {
          notify(getLang("global.notification.leadEditFailed"), {
            type: ToastType.ERROR,
            autoClose: true
          });
        }
      yield put(userDetailUpdateFailed(result));
      try{
        if (action.payload && action.payload.callback) {
          action.payload.callback(false);
        }
      }catch(err){
        console.log("CALLBACK METHOD CALLED ISSUE");
        try {
          CLIENT_ALIAS &&
            leadId &&
            eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
              method: "editAppFormError 04",
              error: err,
              logFrom,
              currentStep,
              currentStep_reducer,
              currentStep_ls,
              deviceDetect: rdd.deviceDetect(),
              formConfigURL: formConfigUrlReducer,
            });
        } catch (error) {
          
        }
        IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_editAppFormError_${leadId}`, properties: { error:err,logFrom,currentStep } });
      }
    }
  }
}

export function* getClientInfo(action) {
  //CAll api
  try {
    yield put(clientInfoRequested(""));
    let validResult = null;
    OfflineStorage.deleteItem(STORAGE_KEY.CHATBOT_CONFIG,STORAGE_TYPE.LOCAL);
    const clientConfig = yield select(getClientInfoData); // <-- get reducer data

    if (clientConfig && Object.keys(clientConfig).length) {
      yield put(clientInfoSuccess(clientConfig));
    } else if (
      false &&
      !!OfflineStorage.getItem(
        STORAGE_KEY.CLIENT_INFO_REDUCER,
        STORAGE_TYPE.LOCAL,
        false
      ) &&
      !!JSON.parse(
        OfflineStorage.getItem(
          STORAGE_KEY.CLIENT_INFO_REDUCER,
          STORAGE_TYPE.LOCAL,
          false
        )
      ) &&
      Object.keys(
        JSON.parse(
          OfflineStorage.getItem(
            STORAGE_KEY.CLIENT_INFO_REDUCER,
            STORAGE_TYPE.LOCAL,
            false
          )
        )
      ).length
    ) {
      /* skipped this logic to remove check on local storage for client configuration json */
      yield put(
        clientInfoSuccess(
          JSON.parse(
            OfflineStorage.getItem(
              STORAGE_KEY.CLIENT_INFO_REDUCER,
              STORAGE_TYPE.LOCAL
            )
          )
        )
      );
    } else {
      if(!!action.payload.rootFolderURL && action.payload.data!==action.payload.rootFolderURL){
        const resultAll = yield all([call(Services.getClientInfo,action.payload.data),call(Services.getClientInfo, action.payload.rootFolderURL)]);      
        if(!!resultAll && Array.isArray(resultAll) && resultAll.length>0){
          if(resultAll[0]!==null){
            validResult=resultAll[0];
          }else if(resultAll[1]!==null){
            validResult=JSON.parse(JSON.stringify(resultAll[1]));
          }
        }
      }else if(action.payload && !!action.payload.data){
        validResult = yield call(Services.getClientInfo, action.payload.data);
      }
      if (validResult) {
        OfflineStorage.setItem(
          STORAGE_KEY.CLIENT_INFO_REDUCER,
          JSON.stringify(validResult),
          STORAGE_TYPE.LOCAL,
          false
        );
        yield put(clientInfoSuccess(validResult));
        yield put({ type: actionTypes.MASTERDATA_INIT });

        yield take([masterDataInit, masterDataFail, masterDataCompleted]);
      } else {
        throw new Error(validResult);
      }
    }
  } catch (error) {
    logError(error);
    yield put(clientInfoFail(error));
  }
}

export function* getDomainConfig(action) {
  //CALL appi
  try {
    yield put(domainConfigRequested(""));
    const domainConfig = yield select(getDomainConfigData); // <-- get reducer data
    if(domainConfig) {
      yield put(domainConfigSuccess(domainConfig));
    } else {
      const result = yield call(
        Services.getDomainConfig,
        !!action?.payload.data ? action.payload.data : ""
      );
      if(result) {
        yield put(domainConfigSuccess(result));
        yield put({ type: actionTypes.MASTERDATA_INIT });
  
        //yield take([masterDataInit, masterDataFail, masterDataCompleted]);
      } else {
        yield put(domainConfigFail());
        throw new Error(result);
      }
    }
  } catch (error) {
    logError(error);
    yield put(domainConfigFail(error));
  }
}

export function* getUserInfo(action) {
  //CAll api  
  const appDataReducer = yield select(getAppDataReducer); 
  const clientInfo = yield select(getClientInfoData); // <-- get reducer data 
  let accessToken = OfflineStorage.getItem(
    STORAGE_KEY.ACCESS_TOKEN,
    STORAGE_TYPE.LOCAL
  );
  let tokenDecoded = jwt_decode(accessToken);  
  let leadId = OfflineStorage.getItem(
    STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,
    STORAGE_TYPE.LOCAL
  );
  leadId = !!leadId ? leadId : tokenDecoded[ACCESS_TOKEN_LEAD_KEY]
  const CLIENT_ALIAS =
    !isNil(clientInfo) && !isNil(get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`)) 
        ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
        : "";
        
  const currentStep = appDataReducer.currentStep || OfflineStorage.getItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,STORAGE_TYPE.LOCAL);
  const currentStep_reducer = appDataReducer.currentStep;
  const currentStep_ls = OfflineStorage.getItem(`${STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP}`,STORAGE_TYPE.LOCAL);
  const hyperlink = window.location.href;
  const logFrom = get(action,`payload.logFrom`);
  const isCounselorAccessActive = !!OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL) && !!OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID, STORAGE_TYPE.LOCAL)  && !!OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL)
  try {
    try {
      window.clarity("set", "eeIdentifier", `${CLIENT_ALIAS}${leadId}`); 
      const prn = tokenDecoded ? tokenDecoded[ACCESS_TOKEN_CLAIMS[STORAGE_KEY.PRN]] : null;
      if(prn)
      window.clarity("set", "eePRN", `${CLIENT_ALIAS}_${prn}`); 
    } catch (error) {
      console.log("clarity not found");
    }
    yield put(userDetailRequested(""));
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:'leadApplicationDetailsReq',logFrom,currentStep,currentStep_reducer,currentStep_ls,hyperlink,deviceDetect:rdd.deviceDetect()} );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_leadApplicationDetailsReq_${leadId}`, properties:{logFrom,currentStep,currentStep_reducer,currentStep_ls,hyperlink} })
    const result = yield call(Services.getUserDetail);
    try {
      try {
        //if(logFrom===`continue-to-application-validate-Login` && !!currentStep){
        // if(!!currentStep){
        //   const resultKeyValueAttributes = !isNil(result) && !isNil(get(result,`data.metaData.keyValueAttributes`)) && get(result,`data.metaData.keyValueAttributes`);
        //   const exceptionRefreshCounter = OfflineStorage.getItem("exceptionRefreshCounter",STORAGE_TYPE.LOCAL);
        //   if(!exceptionRefreshCounter && (!resultKeyValueAttributes || !Array.isArray(resultKeyValueAttributes) || resultKeyValueAttributes.length===0)){
        //     CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:'leadApplicationDetailsRes_exception_Refresh_browser', data:result.data,logFrom,currentStep,currentStep_reducer,currentStep_ls,hyperlink,deviceDetect:rdd.deviceDetect(),ACCESS_TOKEN:tokenDecoded });
        //     OfflineStorage.setItem("exceptionRefreshCounter",1,STORAGE_TYPE.LOCAL)
        //     window.location.reload();
        //   }else{
        //     !!exceptionRefreshCounter && OfflineStorage.deleteItem("exceptionRefreshCounter",STORAGE_TYPE.LOCAL)
        //   }
        // }
      } catch (error) {

      }
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:'leadApplicationDetailsRes', data:result.data,logFrom,currentStep,currentStep_reducer,currentStep_ls,hyperlink,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_leadApplicationDetailsRes_${leadId}`, properties: { data:result.data,logFrom,currentStep,currentStep_reducer,currentStep_ls,hyperlink,deviceDetect:rdd.deviceDetect() } })
    if (result.status === 200 || result.status === 201) {
      if(window.extraaedge && window.extraaedge.isZIPYActive){        
        zipy.identify(`${CLIENT_ALIAS}-${leadId}`, {
          email: get(result,`data.user.email`),
          mobileNumber: get(result,`data.user.mobileNumber`),
          customerName: CLIENT_ALIAS,
          leadId,
          userId:get(result,`data.user.id`)
        });        
      }
      const masterDataReducer = yield select(getAllMasterData);
      try{
        if(get(result,`data.lead.leadHistoryStatusId`))
        yield put(setLeadHistoryStatusId(get(result,`data.lead.leadHistoryStatusId`)));
      }catch(err){

      }
      
      !isNil(get(result,`data.user.prn`)) && !!get(result,`data.user.prn`) && OfflineStorage.setItem(
        STORAGE_KEY.PRN,
        get(result,`data.user.prn`),
        STORAGE_TYPE.LOCAL
      );
      //call get counselor details api      
      if(!isCounselorAccessActive && !isNil(clientInfo) && !!get(clientInfo,`chatBot.isActive`) && !!get(clientInfo,`chatBot.chatConfigParameters`) && !!get(clientInfo,`chatBot.js_script_path`)){
          //chatbot isActive, so need to create chatConfig at local storage
          let requestParams=clientInfo.chatBot.chatConfigParameters;
          const parametersMasterDataMapping=clientInfo.chatBot.parametersMasterDataMapping;          
          const userDetail=get(result,`data`); 
          forIn(requestParams,(requestValue,requestKey)=>{
            if(has(userDetail,requestValue)){
            set(requestParams,requestKey,!!get(userDetail,requestValue)?get(userDetail,requestValue):"");            
            if(!!get(userDetail,requestValue) && !!parametersMasterDataMapping && !isNil(get(parametersMasterDataMapping,requestKey)) && !isNil(get(masterDataReducer,parametersMasterDataMapping[requestKey])) && Array.isArray(masterDataReducer[parametersMasterDataMapping[requestKey]]) && masterDataReducer[parametersMasterDataMapping[requestKey]].length>0){
              const mappedMasterDataItem=find(masterDataReducer[parametersMasterDataMapping[requestKey]],{id:requestParams[requestKey]});
              !!mappedMasterDataItem && !isNil(mappedMasterDataItem.name) && set(requestParams,requestKey,mappedMasterDataItem.name);
            }
            }
          });
          requestParams = replaceTokenOfPDFAPIRequest(
            requestParams,
            get(userDetail,`lead.id`),
            clientInfo
          );
          OfflineStorage.setItem(STORAGE_KEY.CHATBOT_CONFIG,JSON.stringify(requestParams),STORAGE_TYPE.LOCAL);
        }  
      if(isCounselorAccessActive){
        yield put({
          type: actionTypes.COUNSELOR_DETAIL_INIT,
          payload: {
            cid: OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL)
          }
        })
      }else if(!isNil(get(result,`data.lead.id`)) && !!get(result,`data.lead.id`) && window.location.pathname !== PAGES.LEAD_TO_APPLICATION_CONTINUE){
        //lead event call if isCounselorAccessActive is false
        //check ApplicationFirstLoginDate avail in MasterKeys
        let indexedKeyValueAttributes = get(result,`data.metaData.indexedKeyValueAttributes`);  
        indexedKeyValueAttributes=!!indexedKeyValueAttributes && Array.isArray(indexedKeyValueAttributes) && indexedKeyValueAttributes.length>0 ? indexedKeyValueAttributes : null;
        const isOnlineStatusActive = clientInfo && !!get(clientInfo,"onlineStatus.isActive");
        let ApplicationFirstLoginDate_masterKeyDetails=null;
        let ApplicationLastActiveDate_masterKeyDetails=null;
        let ApplicationOnlineStatus_masterKeyDetails=null;
        if(!isNil(masterDataReducer) && !isNil(get(masterDataReducer,"masterKeys")) && Array.isArray(masterDataReducer.masterKeys) && masterDataReducer.masterKeys.length>0){
          ApplicationFirstLoginDate_masterKeyDetails = !isNil(masterDataReducer) && !isNil(get(masterDataReducer,"masterKeys")) && Array.isArray(masterDataReducer.masterKeys) && masterDataReducer.masterKeys.length>0 ? find(masterDataReducer.masterKeys,{name:LEAD_EVENT_MASTER_KEYS.APPLICATION_FIRST_LOGIN_DATE,isActive:true}) : null;
          ApplicationLastActiveDate_masterKeyDetails = !isNil(masterDataReducer) && !isNil(get(masterDataReducer,"masterKeys")) && Array.isArray(masterDataReducer.masterKeys) && masterDataReducer.masterKeys.length>0 ? find(masterDataReducer.masterKeys,{name:LEAD_EVENT_MASTER_KEYS.APPLICATION_LAST_ACTIVE_DATE,isActive:true}) : null;
          ApplicationOnlineStatus_masterKeyDetails = isOnlineStatusActive && !isNil(masterDataReducer) && !isNil(get(masterDataReducer,"masterKeys")) && Array.isArray(masterDataReducer.masterKeys) && masterDataReducer.masterKeys.length>0 ? find(masterDataReducer.masterKeys,{name:LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,isActive:true}):null;
        }
        
        if(!!ApplicationFirstLoginDate_masterKeyDetails && !(!!indexedKeyValueAttributes && find(indexedKeyValueAttributes,{keyId:ApplicationFirstLoginDate_masterKeyDetails.id}))){
          try{
            const leadId=get(result,`data.lead.id`);
              //add eeAzurelogs block below
              const requestBody = {
                "LeadId": leadId,
                "Name": LEAD_EVENT_MASTER_KEYS.APPLICATION_FIRST_LOGIN_DATE,
                "type": "date",
                "value": ConvertToUTCUsingMoment((new Date()).toISOString()),
                "eventType":find(indexedKeyValueAttributes,item=>{return item.keyId!==ApplicationFirstLoginDate_masterKeyDetails.id;})?"update":"add"
              }
              const ApplicationFirstLoginDate_result = yield call(Services.requestAddLeadEvent,{...requestBody});
              if((ApplicationFirstLoginDate_result.status=== 200 || ApplicationFirstLoginDate_result.status === 201) && !isNil(get(ApplicationFirstLoginDate_result,`data.result`))){
                if(!indexedKeyValueAttributes){
                  indexedKeyValueAttributes=[];
                }
                if(indexedKeyValueAttributes.length>0){
                  indexedKeyValueAttributes=filter(indexedKeyValueAttributes,item=>{return item.keyId!==ApplicationLastActiveDate_masterKeyDetails.id;})
                }
                indexedKeyValueAttributes.push(get(ApplicationFirstLoginDate_result,`data.result`))
                set(result,`data.metaData.indexedKeyValueAttributes`,indexedKeyValueAttributes);
              }
          }catch(e1){
            console.log(`e1 ${e1}`);
          }
          
        }
        if(!!ApplicationLastActiveDate_masterKeyDetails){
          try{
            const requestBody = {
              "LeadId": get(result,`data.lead.id`),
              "Name": LEAD_EVENT_MASTER_KEYS.APPLICATION_LAST_ACTIVE_DATE,
              "type": "date",
              "value": ConvertToUTCUsingMoment((new Date()).toISOString()),
              "eventType":!!indexedKeyValueAttributes && find(indexedKeyValueAttributes,item=>{return item.keyId!==ApplicationLastActiveDate_masterKeyDetails.id;})?"update":"add"
            };
            const ApplicationLastActiveDate_result = yield call(Services.requestAddLeadEvent,{...requestBody}); 
            if((ApplicationLastActiveDate_result.status=== 200 || ApplicationLastActiveDate_result.status === 201) && !isNil(get(ApplicationLastActiveDate_result,`data.result`))){
              if(!indexedKeyValueAttributes){
                indexedKeyValueAttributes=[];
              }
              if(indexedKeyValueAttributes.length>0){
                indexedKeyValueAttributes=filter(indexedKeyValueAttributes,item=>{return item.keyId!==ApplicationLastActiveDate_masterKeyDetails.id;})
              }
              indexedKeyValueAttributes.push(get(ApplicationLastActiveDate_result,`data.result`))
              set(result,`data.metaData.indexedKeyValueAttributes`,indexedKeyValueAttributes);
            }
          }catch(e2){
            console.log(`e2 ${e2}`);
          }
        }
        const isCountinuePage = window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE; 
        if(!!ApplicationOnlineStatus_masterKeyDetails && !isCounselorAccessActive && !isCountinuePage){
          try{
            const masterKeyData = !!indexedKeyValueAttributes && find(indexedKeyValueAttributes,item=>{return item.keyId===ApplicationOnlineStatus_masterKeyDetails.id;})
            const allApplicationsDataMappedWithLeadIds = get(appDataReducer,"allApplicationsDataMappedWithLeadIds");
            const rowId = get(masterKeyData,"id");
            const multipleLeadEvents = getIsLeadOnlineAPIParams(allApplicationsDataMappedWithLeadIds, ApplicationOnlineStatus_masterKeyDetails)
            const requestBody = {
              "LeadId": get(result,`data.lead.id`),
              "Name": LEAD_EVENT_MASTER_KEYS.APPLICATION_ONLINE_STATUS,
              "type": "bool",
              "value": true,
              "eventType": masterKeyData ?"update":"add",
              "id": checkIfArray(multipleLeadEvents) ? rowId : rowId || 0,
              "MultipleLeadEvents": multipleLeadEvents
            };
            const ApplicationOnlineStatus_result = yield call(Services.requestAddLeadEvent,{...requestBody}); 
            if((ApplicationOnlineStatus_result.status=== 200 || ApplicationOnlineStatus_result.status === 201) && !isNil(get(ApplicationOnlineStatus_result,`data.result`))){
              if(!indexedKeyValueAttributes){
                indexedKeyValueAttributes=[];
              }
              if (
                requestBody.eventType === "add" &&
                Array.isArray(multipleLeadEvents) &&
                multipleLeadEvents.length <= 1
              ) {
                indexedKeyValueAttributes.push(
                  get(ApplicationOnlineStatus_result, `data.result`)
                );
              } else {
                indexedKeyValueAttributes = indexedKeyValueAttributes.map(
                  (obj) => {
                    if (obj.id === rowId) {
                      return {
                        ...obj,
                        boolValue: true,
                      };
                    }
                    return obj;
                  }
                );
              }
              set(result,`data.metaData.indexedKeyValueAttributes`,indexedKeyValueAttributes);
            }
          }catch(e3){
            console.log(`e3 ${e3}`);
          }
        }
      }
      //Alwaus call below block of code after trace lead event api
      leadObjectManager.init(
        result.data,
        Object.assign({}, masterDataReducer)
      );
      const formReducer = yield select(getFormReducer);
      if (formReducer && formReducer.data) {
        const formData = yield call(
          updateFormDataFromUserInfo,
          formReducer.data
        );
        yield put({
          type: FormAction.UPDATE_FORM_DATA,
          payload: {
            data: formData
          }
        });
        yield put({
          type: FormAction.UPDATE_FORM_INITIAL_DATA,
          payload: {
            data: Object.assign({}, formData)
          }
        });
      }
      
      yield put(userDetailSuccess(result.data));
      const applicationFormNumber = get(result.data, "lead.applicationFormNumber");
      if (
        !applicationFormNumber &&
        window.location.pathname === PAGES.FORM
      ) {
        //If user has no application form number then redirect to dashboard 
        var searchParams = new URLSearchParams(window.location.search);
        window.location = window.location.search
          ? `${PAGES.DASHBOARD}?${searchParams.toString()}`
          : `${PAGES.DASHBOARD}`;
      }
      if (
        action &&
        action.payload &&
        action.payload.data &&
        !!action.payload.data.nextAction &&
        action.payload.data.payload
      ) {
        yield put({
          type: action.payload.data.nextAction,
          payload: action.payload.data.payload
        });
      }
    } else {
      try {
        CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"leadApplicationDetailsError 01", error:result,logFrom,currentStep,deviceDetect:rdd.deviceDetect() } );
      } catch (error) {
        
      }
      IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_leadApplicationDetailsError_${leadId}`, properties: { error:result,logFrom,currentStep } })
      //throw new Error(result);
    }

    /*}*/
  } catch (error) {
    logError(error);
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"leadApplicationDetailsError 02", error:error,logFrom,currentStep,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_leadApplicationDetailsError_${leadId}`, properties: { error:error,logFrom,currentStep } })
    yield put(userDetailFailed(error));
  }
}

export function* updateCurrentStepNumber(action) {
  //CAll api
  try {
    yield put(updateCurrentStep(action.payload.data));
  } catch (error) {
    logError(error);
  }
}

export function* requestGeneratePDF(action) {
  //CAll api
  const { leadId,isDownload=false } = action.payload.data || {};
  try {
    yield put(getLeadDocumentsRequested(leadId));
    yield put(generatePDFRequested(""));
    const result = yield call(Services.requestGeneratePDF, action.payload.data);
    if (result.status === 200 || result.status === 201) {
      if(result && result.data){
        if(checkIfObject(result.data)) {
          const { fileName } = result.data;
          if (isDownload && fileName && getMediaLiveURL(fileName)) {
            yield call(createAnchorTagSaga, getMediaLiveURL(`ApplicationForm/${fileName}`), "", "", true);
          }          
        }
        yield put(getLeadDocumentsSuccess(leadId));
        yield put(generatePDFSuccess(result.data))
      }
    } else {
      throw new Error(result);
    }
  } catch (error) {
    notify(getLang("global.notification.generatePDFFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });
    logError(error);
    yield put(getLeadDocumentsFail(leadId));
    yield put(generatePDFFail(error));
  }
}

export function* updateUserMetaData(action) {  
  const appDataReducer = yield select(getAppDataReducer); 
  const clientInfo = yield select(getClientInfoData); // <-- get reducer data  
  const masterData = yield select(getAllMasterData); // <-- get reducer data
  const currentStep = appDataReducer.currentStep || OfflineStorage.getItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,STORAGE_TYPE.LOCAL);
  const currentStep_reducer = appDataReducer.currentStep;
  const currentStep_ls = OfflineStorage.getItem(`${STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP}`,STORAGE_TYPE.LOCAL);
  //CAll api
  const CLIENT_ALIAS =
    !isNil(clientInfo) && !isNil(get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`)) 
        ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
        : "";

  const leadId=get(action,`payload.data.leadId`);
  const logFrom=get(action,`payload.logFrom`);
  try {
    /**Check appform index is in correct order */
    const isStepNavigationLinear = get(clientInfo,`LeadstatusDetails.stepNavigation`)=== STEP_NAVIGATION_TYPE.LINEAR;
    if(isStepNavigationLinear){
      //if step navigation is linear then only this loop will check appformCompletedStepIndex is in order or not to show force refresh popup
      const masterKeysCollection = !isNil(masterData) && getMasterDataByKey(`masterKeys`,masterData);
      const appfromCompletedStepIndexMasterKeyConfig=checkIfArray(masterKeysCollection) ? find(masterKeysCollection,item=>{return item.name===STORAGE_KEY.APPFORM_NAVIGATION_STATUS;}):null;
      let keyValueMetaData = get(action.payload.data,`${META_DATA_TYPE.NON_INDEX_META_TYPE}`);
      if(isNil(keyValueMetaData) || !Array.isArray(keyValueMetaData)){
        keyValueMetaData=[];
      }
      if(!!appfromCompletedStepIndexMasterKeyConfig && !!keyValueMetaData && Array.isArray(keyValueMetaData)){
        let appfromCompletedStepIndexMetaData=find(keyValueMetaData,{keyId:appfromCompletedStepIndexMasterKeyConfig.id});
        if(!!appfromCompletedStepIndexMetaData?.value){
          let appfromCompletedStepIndexMetaDataValue=JSON.parse(appfromCompletedStepIndexMetaData.value);
          let currentAppfromCompletedStepIndex=get(appfromCompletedStepIndexMetaDataValue,STORAGE_KEY.APPFORM_COMPLETED_STEP_INDEX);
          currentAppfromCompletedStepIndex.sort();
          if (get(window,`extraaedge.showForceRefreshPopup.isActive`) && !isArrayValueInSequence(currentAppfromCompletedStepIndex)) {
            try {
              CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{ method:"updateMetaData_INCORRECT_STEP_INDEX","appfromCompletedStepIndex":currentAppfromCompletedStepIndex,logFrom,currentStep,currentStep_reducer,currentStep_ls,deviceDetect:rdd.deviceDetect() } );
            } catch (error) {
              
            }
            IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaData_INCORRECT_STEP_INDEX_${leadId}`, properties: { "appfromCompletedStepIndex":currentAppfromCompletedStepIndex,logFrom,currentStep } });
            yield put({ type: actionTypes.SHOW_REFRESH_WINDOW_POPUP });
            return;
          }
        }          
      } 
    }
    
    /* END of check appform index is in correct order */
    yield put(userMetaDataUpdateRequested(""));
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataReq", data:action.payload.data,logFrom,currentStep,currentStep_reducer,currentStep_ls,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataReq_${leadId}`, properties: { data:action.payload.data,logFrom,currentStep } });
    const result = yield call(Services.updateUserMetaData, ({...action.payload.data, EESourceId : LEAD_EESOURCE_IDS.APPFORM}));
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataRes", data:!!get(result,`data`)?result.data:result,logFrom,currentStep ,currentStep_reducer,currentStep_ls,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataRes_${leadId}`, properties: { data:!!get(result,`data`)?result.data:result,logFrom,currentStep } })
    if (result.status === 200 || result.status === 201) {
      result && (yield put(userMetaDataUpdateSuccess(result.data)));
      const currentFormData = yield select(getFormData); // <-- get reducer data
      if (currentFormData) {
        yield put({
          type: FormAction.UPDATE_FORM_INITIAL_DATA,
          payload: { data: Object.assign({}, currentFormData) }
        });
      }
      //leadObjectManager.setUserInfo(result.data);
      if (
        !isNil(action) &&
        !isNil(get(action, "payload.generateTemplatePDFDetails")) &&
        Object.keys(get(action, "payload.generateTemplatePDFDetails")).length
      ) {
        yield put({
          type: actionTypes.GENERATE_TEMPLATE_PDF_INIT,
          payload: { data: get(action, "payload.generateTemplatePDFDetails") }
        });
      }
      try{
        if (
          !isEmpty(action.payload) &&
          !isEmpty(action.payload.callback) &&
          !isEmpty(action.payload.nextStepIndex)
        ) {
          action.payload.callback(
            true,
            action.payload && !!action.payload.nextStepIndex
              ? action.payload.nextStepIndex
              : null
          );
        }
      }catch(err){
        try {
          CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataResError 01", error:err,logFrom,currentStep,deviceDetect:rdd.deviceDetect() } );
        } catch (error) {
          
        }
        IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataResError_${leadId}`, properties: { error:err,logFrom,currentStep } })
        console.log(err);
      }      
    } else {
      try {
        CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataResError 02", error:result,logFrom,currentStep ,deviceDetect:rdd.deviceDetect()} );
      } catch (error) {
        
      }
      IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataResError_${leadId}`, properties: { error:result,logFrom,currentStep } })
      throw new Error(result);
    }
  } catch (error) {
    logError(error);
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataResError 03", error:error,logFrom,currentStep ,deviceDetect:rdd.deviceDetect()} );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataResError_${leadId}`, properties: { error:error,logFrom,currentStep} })
    notify(getLang("global.notification.updateMetaFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });
    yield put(userMetaDataUpdateFailed(error));
    try{
      if (action.payload && action.payload.callback) {
        action.payload.callback(false);
      }
    }catch(err){
      try {
        CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"updateMetaDataResError 04", error:err,logFrom,currentStep,deviceDetect:rdd.deviceDetect() } );
      } catch (error) {
        
      }
      IS_APPINSIGHT_ACTIVE && CLIENT_ALIAS && leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_updateMetaDataResError_${leadId}`, properties: { error:err,logFrom,currentStep } })
      console.log(err);
    }
  }
}

export function* requestGeneratePreviewPDF(action) {
  //CAll api
  try {
    yield put(generatePreviewPDFRequested(""));
    const result = yield call(
      Services.requestGeneratePreviewPDF,
      action.payload.data
    );
    if (result.status === 200 || result.status === 201) {
      result &&
        result.data &&
        (yield put(generatePreviewPDFSuccess(result.data)));
      if (
        action &&
        action.payload &&
        action.payload.data &&
        action.payload.data.callback
      ) {
        action.payload.data.callback(result.data);
      }
    } else {
      throw new Error(result);
    }
  } catch (error) {
    notify(getLang("global.notification.generatePreviewPDFFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });
    logError(error);
    yield put(generatePreviewPDFFail(error));
  }
}

export function* requestUploadProfilePicture(action) {
  try {
    yield put(uploadProfilePictureRequested(""));
    let file = action.payload.file;
    const fd = new FormData();
    fd.append("uploadFile", file);
    fd.append("userId", action.payload.userId);
    fd.append("clientAlias", action.payload.clientAlias);

    const result = yield call(Services.uploadFile, action.payload.url, fd);
    if (result.status === 200 || result.status === 201) {
      result &&
        result.data &&
        (yield put(uploadProfilePictureSuccess(result.data)));

      const userDetail = yield select(getuserDetailData); // <-- get reducer data
      leadObjectManager.setUserInfo(userDetail);
      const formReducer = yield select(getFormReducer);
      if (formReducer && formReducer.data) {
        const formData = yield call(
          updateFormDataFromUserInfo,
          formReducer.data
        );
        yield put({
          type: FormAction.UPDATE_FORM_DATA,
          payload: {
            data: JSON.parse(JSON.stringify(formData))
          }
        });
        yield put({
          type: FormAction.UPDATE_FORM_INITIAL_DATA,
          payload: {
            data: Object.assign({}, JSON.parse(JSON.stringify(formData)))
          }
        });
      }
    } else {
      throw new Error(result);
    }
  } catch (error) {
    notify("Failed to upload profile photo", {
      type: ToastType.ERROR,
      autoClose: true
    });
    logError(error);
    yield put(uploadProfilePictureFailed(error));
  }
}

export function* requestGenerateTemplatePDF(action) {
  //CAll api
  try {
    yield put(generateTemplatePDFRequested(""));
    const result = yield call(Services.requestGenerateTemplatePDF, action.payload.data);
    if (result.status === 200 || result.status === 201) {
      result && result.data && (yield put(generateTemplatePDFSuccess(result.data)));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    /*notify(getLang("global.notification.generateTemplatePDFFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });*/
    logError(error);
    yield put(generateTemplatePDFFail(error));
  }
}


export function* requestPushAppformLead(action) {
  //CAll api
  try {
    yield put(pushAppformLeadRequested(""));
    const result = yield call(Services.pushAppForm, action.payload.data);
    if (result.status === 200 || result.status === 201) {
      //result && result.data && (yield put(pushAppformLeadSuccess(result.data)));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    /*notify(getLang("global.notification.generateTemplatePDFFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });*/
    logError(error);
    yield put(pushAppformLeadFail(error));
  }
}

export function* watchAppGetAllMaster() {
  yield takeLatest(actionTypes.MASTERDATA_INIT, getAllMaster);
}
export function* watchAppValidateLogin() {
  yield takeLatest(actionTypes.VALIADTE_LOGIN_INTI, validateLogin);
}

export function* watchUpdateUserInfoData() {
  yield takeLatest(actionTypes.USER_INFO_UPDATE, updateUserInfoData);
}
export function* watchGetClientInfoData() {
  yield takeLatest(actionTypes.CLIENT_INFO_INIT, getClientInfo);
}
export function* watchGetDomainConfigData() {
  yield takeLatest(actionTypes.DOMAIN_CONFIG_INIT, getDomainConfig);
}
export function* watchGetUserInfo() {
  yield takeLatest(actionTypes.USER_DETAIL_INIT, getUserInfo);
}
export function* watchUpdateCurrentStepNumber() {
  yield takeLatest(
    actionTypes.UPDATE_CURRENT_STEP_NUMBER,
    updateCurrentStepNumber
  );
}

export function* watchRequestGeneratePDF() {
  yield takeLatest(actionTypes.GENERATE_PDF_INTI, requestGeneratePDF);
}

export function* watchUpdateUserMetaData() {
  yield takeLatest(actionTypes.USER_METADATA_UPDATE, updateUserMetaData);
}

export function* watchRequestGeneratePreviewPDF() {
  yield takeLatest(
    actionTypes.GENERATE_PREVIEW_PDF_INTI,
    requestGeneratePreviewPDF
  );
}

export function* watchUploadUserProfile() {
  yield takeLatest(
    actionTypes.UPLOAD_PROFILE_PICTURE_INIT,
    requestUploadProfilePicture
  );
}


export function* watchRequestGenerateTemplatePDF() {
  yield takeLatest(actionTypes.GENERATE_TEMPLATE_PDF_INIT, requestGenerateTemplatePDF);
}
export function* watchRequestPushAppformLead() {
  yield takeLatest(actionTypes.PUSH_APP_FORM_LEAD_INIT, requestPushAppformLead);
}

export function* watchGetPaymentTransactionHistory() {
  yield takeLatest(actionTypes.GET_PAYMENT_TRANSACTION_HISTORY, getPaymentTransactionHistory)
}

export function* getPaymentTransactionHistory(action) {
  //Call Api
  try {
    yield put(paymentTransactionHistoryRequested(""));
    const result = yield call(Services.getPaymentDetailList, get(action,`payload.data`));
    if (result && get(result,`data.code`) && get(result,`data.data`) && (result.data.code === 200 || result.data.code === 201)) {
      let transactions = result?.data?.data;
      let uniqueTransByTransactionIdIsRequired = true;
      if(checkIfArray(transactions)) {
        const domainConfig = yield select(getDomainConfigData);
          const {dashboard : {myApplication: { showOnlyCurrentUkcCard } = {}} = {}} = domainConfig || {};
          if(showOnlyCurrentUkcCard) {
            const allApplicationsData = yield select(getAllApplicationData);
            const {lead: {id: activeLeadId}} = checkIfArray(allApplicationsData) && allApplicationsData[0]  || {};
            if (checkIfArray(allApplicationsData) ) {
              const foundItem = transactions.find(item => item?.leadId === activeLeadId);
              transactions = [];
              if (foundItem) {
                transactions.push(foundItem);
              }
            }
          }
      }
      if (
        checkIfArray(transactions) &&
        PAYMENT_METHOD_IDS_WHICH_HAS_DUPLICATE_REFRENCE_KEY.includes(
          get(transactions[0], "paymentModeId")
        )
      ) {
        uniqueTransByTransactionIdIsRequired = false;
      }
      const uniqueTransByTransactionId = new Map(); //For grouping duplicate payment history
      checkIfArray(transactions) && uniqueTransByTransactionIdIsRequired && transactions.forEach(item => {
        const { transactionId, entityId } = item;
        if (!uniqueTransByTransactionId.has(transactionId)) {
          uniqueTransByTransactionId.set(transactionId, {...item, entityId: [entityId]});
        } else {
          uniqueTransByTransactionId.get(transactionId).entityId.push(entityId);
        } 
      })
      yield put(paymentTransactionHistorySuccess(uniqueTransByTransactionIdIsRequired ? Array.from(uniqueTransByTransactionId.values()) : transactions));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    yield put(paymentTransactionHistoryFailed(error));
  }
}


export function* getCounselorInfo(action) {
  //CAll api
  try {
    yield put(counselorDetailRequested(""));
    const result = yield call(Services.getConuselorInfo, action.payload.cid);
    if (result.status === 200 || result.status === 201) {
      OfflineStorage.setItem(STORAGE_KEY.COUNSELOR_DETAILS,result.data,STORAGE_TYPE.LOCAL);
      yield put(counselorDetailSuccess(result.data));
    } else {
      OfflineStorage.deleteItem(STORAGE_KEY.COUNSELOR_DETAILS,STORAGE_TYPE.LOCAL);
      throw new Error(result);
    }
  } catch (error) {
    logError(error);
    yield put(counselorDetailFailed(error));
  }
}
export function* watchGetCounselorInfo() {
  yield takeLatest(actionTypes.COUNSELOR_DETAIL_INIT, getCounselorInfo);
}



export function* requestAddLeadEvent(action) {
  //CAll api
  try {
    yield put(addLeadEventRequested(""));
    const result = yield call(Services.requestAddLeadEvent, action.payload);
    if (result.status === 200 || result.status === 201) {
      result && result.data && (yield put(addLeadEventSuccess(result.data)));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    /*notify(getLang("global.notification.addLeadEventFailed"), {
      type: ToastType.ERROR,
      autoClose: true
    });*/
    logError(error);
    yield put(addLeadEventFail(error));
  }
}
export function* watchRequestAddLeadEvent() {
  yield takeLatest(actionTypes.ADD_LEAD_EVENT_INTI, requestAddLeadEvent);
}




export function* requestCheckValuesInHistory(action) {
  //CAll api
  try {
    yield put(getValuesFromHistoryRequested(""));
    const result = yield call(Services.checkValuesFromHistory,get(action,`payload.data`));
    if (result.status === 200 || result.status === 201) {
      result && result.data && (yield put(getValuesFromHistorySuccess({key:get(action,`payload.data.key`),data:result.data,params:action.payload})));
    } else {
      throw new Error(result);
    }
  } catch (error) {
    logError(error);
    yield put(getValuesFromHistoryFail(error));
  }
}
export function* watchRequestCheckValuesInHistory() {
  yield takeLatest(actionTypes.CHECK_VALUES_IN_HISTORY_INTI, requestCheckValuesInHistory);
}


export function* swapCourseEditLead(action) {
  //CAll api  
  const masterData = yield select(getAllMasterData);
  const appDataReducer = yield select(getAppDataReducer); 
  const clientInfo = yield select(getClientInfoData); // <-- get reducer data
  const CLIENT_ALIAS =
    !isNil(clientInfo) && !isNil(get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`)) 
        ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
        : "";
  const leadId=get(action,`payload.data.lead.id`);
  const currentStep = appDataReducer.currentStep || OfflineStorage.getItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,STORAGE_TYPE.LOCAL);
  try {
    yield put(swapCourseEditLeadRequested(action.payload.isAutoSaveRequest));
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormReq", data:action.payload.data, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormReq_${leadId}`, properties: { data:action.payload.data, logFrom:"SwapCourse",currentStep } })
    const result = yield call(Services.updateUserDetail, action.payload.data, masterData);
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormRes", data:!!result.data?result.data:result, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormRes_${leadId}`, properties: { data:!!result.data?result.data:result, logFrom:"SwapCourse",currentStep } })
    if (result.status === 200 || result.status === 201) {
      const applicationCardData = appDataReducer?.applicationCardData || {};
      const { SwapEntity } = action.payload.data || {};
      if(SwapEntity.NewEntity && applicationCardData?.id && SwapEntity?.OldEntity && SwapEntity?.OldEntity === applicationCardData?.id) {
        let entityDataCollection = getEntityCollection(
          get(masterData, `${STORAGE_KEY.PRICING_CRITERIA}`),
          masterData
        );
        if(checkIfArray(entityDataCollection)) {
          const updatedApplicationData = find(entityDataCollection, { id: SwapEntity.NewEntity });
          checkIfObject(updatedApplicationData) && (yield put(setApplicationCardData(updatedApplicationData)))
        }
      }
      let receiptResponseData={};
      result && result.data && (yield put(swapCourseEditLeadSuccess(result.data)));
      
      leadObjectManager.setUserInfo(result.data);
      if (action.payload && action.payload.formData/* && !!action.payload.isAutoSaveRequest*/) {
        const formReducer = yield select(getFormReducer);
        if (formReducer && formReducer.data) {
          const formData = yield call(
            updateFormDataFromUserInfo,
            formReducer.data
          );          
          yield put({
            type: FormAction.UPDATE_FORM_DATA,
            payload: {
              data: JSON.parse(JSON.stringify(formData))
            }
          });
          yield put({ type: FormAction.UPDATE_FORM_INITIAL_DATA, payload: { data: Object.assign({}, formData) } });
        }        
      }      
      if ( !isNil(action) && !isNil(get(action, "payload.generatePDFDetails")) && Object.keys(get(action, "payload.generatePDFDetails")).length ) {
        yield put({ type: actionTypes.GENERATE_PDF_INTI, payload: { data: get(action, "payload.generatePDFDetails") } });
      }
      if (
        !isNil(action) &&
        !isNil(get(action, "payload.FeesType_1_Receipt_PDF_Details.apiRequest")) &&
        Object.keys(get(action, "payload.FeesType_1_Receipt_PDF_Details.apiRequest")).length && action?.payload?.FeesType_1_Receipt_PDF_Details?.receiptNo
      ) {
        /*yield put({
          type: actionTypes.GENERATE_TEMPLATE_PDF_INIT,
          payload: { data: get(action, "payload.FeesType_1_Receipt_PDF_Details") }
        });*/
        
        yield put({ type: actionTypes.GLOBAL_LOADER_TOGGLE, payload: {flag:true, messaage:getLang("loaderText.generating_FeesType_1_Receipt_PDF","Please wait, we are re-generating your application form fees receipt.") } });
        try{
          let result_feesType_1_Receipt_PDF = yield call(Services.requestGenerateTemplatePDF, action.payload.FeesType_1_Receipt_PDF_Details.apiRequest);
       !!result_feesType_1_Receipt_PDF?.data?.fileName && set(receiptResponseData,action.payload.FeesType_1_Receipt_PDF_Details.receiptNo,result_feesType_1_Receipt_PDF?.data?.fileName);
        }catch(e4){
          console.log(`e4 - ${e4}`);
        }
        
      }
      if (
        !isNil(action) &&
        !isNil(get(action, "payload.FeesType_2_Receipt_PDF_Details.apiRequest")) &&
        Object.keys(get(action, "payload.FeesType_2_Receipt_PDF_Details.apiRequest")).length && action?.payload?.FeesType_2_Receipt_PDF_Details?.receiptNo
      ) {
        
        yield put({ type: actionTypes.GLOBAL_LOADER_TOGGLE, payload: {flag:true, messaage:getLang("loaderText.generating_FeesType_2_Receipt_PDF","Please wait, we are re-generating your course form fees receipt.") } });
        try{
        let result_feesType_2_Receipt_PDF = yield call(Services.requestGenerateTemplatePDF, action.payload.FeesType_2_Receipt_PDF_Details.apiRequest);
        !!result_feesType_2_Receipt_PDF?.data?.fileName && set(receiptResponseData,action.payload.FeesType_2_Receipt_PDF_Details.receiptNo,result_feesType_2_Receipt_PDF?.data?.fileName);
        }catch(e5){
          console.log(`e5 - ${e5}`);
        }
      }
      try{
        if (!!action?.payload?.swapCallback) {
          action.payload.swapCallback(true,!!receiptResponseData && Object.keys(receiptResponseData).length>0 ? receiptResponseData : null,result?.data);
        }
      }catch(err){
        console.log("ISSUE in swapCallback METHOD");
        try {
          CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormResError 01", error:err, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
        } catch (error) {
          
        }
        IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormResError_${leadId}`, properties: { error:err, logFrom:"SwapCourse",currentStep } })
      }
      
    } else {
      try {
        CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormResError 02", error:result, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
      } catch (error) {
        
      }
      IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormResError_${leadId}`, properties: { error:result, logFrom:"SwapCourse",currentStep } })
      //throw new Error(result);
    }
  } catch (error) {
    logError(error);
    try {
      CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormResError 03", error, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
    } catch (error) {
      
    }
    IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormResError_${leadId}`, properties: { error, logFrom:"SwapCourse",currentStep } })
    yield put(swapCourseEditLeadFailed(error));
    try{
      if (action.payload && action.payload.swapCallback) {
        action.payload.swapCallback(false);
      }
    }catch(err){
      console.log("ISSUE in swapCallback METHOD");
      try {
        CLIENT_ALIAS && leadId && eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS,leadId,{method:"swapCourse_editAppFormResError 04", error:err, logFrom:"SwapCourse",currentStep,deviceDetect:rdd.deviceDetect() } );
      } catch (error) {
        
      }
      IS_APPINSIGHT_ACTIVE && !!CLIENT_ALIAS && !!leadId && appInsights.trackEvent({ name: `${CLIENT_ALIAS}_swapCourse_editAppFormResError_${leadId}`, properties: { error:err, logFrom:"SwapCourse",currentStep } })
    }
    
  }
}


export function* watchSwapCourseEditLead() {
  yield takeLatest(actionTypes.SWAP_COURSE_EDIT, swapCourseEditLead);
}


export function* getActivities(action) {
  //CALL API
  try {
    yield put(getActiviesRequested(""));
    const Activities = yield select(getActiviesData); // <-- get reducer data
    if(Activities) {
      yield put(getActiviesSuccess(Activities));
    } else {
      const result = yield call(
        Services.getActivities,
        !!action?.payload.data ? action.payload.data : ""
      );
      if(result?.data) {
        yield put(getActiviesSuccess(result?.data));
      } else {
        yield put(getActiviesFail());
        throw new Error(result);
      }
    }
  } catch (error) {
    logError(error);
    yield put(getActiviesFail(error))
  }
}

export function* watchGetActivities() {
  yield takeLatest(actionTypes.GET_ACTIVITIES_INIT, getActivities);
}

export function* watchGetAllApplicationDetails() {
  yield takeLatest(actionTypes.ALL_APPLICATION_DETAIL_INIT, getAllApplicationDetails);
}


export function* watchGetAllApplicationsConfig() {
  yield takeLatest(actionTypes.ALL_APPLICATIONS_CONFIG_INIT, getAllApplicationsConfig);
}

export function* getAllApplicationDetails() {
  //CALL API
  try {
    const allApplicationsData = yield select(getAllApplicationData); // <-- get reducer data
    const masterData = yield select(getAllMasterData); // <-- get reducer data
    const uniqueKeyCriteria = get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA)
    const applicationFormCriteria = get(masterData, STORAGE_KEY.APPLICATION_FORM_CRIETRIA);
    if(uniqueKeyCriteria) { //If UKC is on then call this API otherwise do not call this 
      yield put(getAllApplicationRequested(""));
      if (allApplicationsData) {
        yield put(getAllApplicationSuccess(allApplicationsData));
      } else {
        const result = yield call(Services.callGetAllApllcationLeadsAPI);
        let allApplicationsData = result?.data
        if (allApplicationsData) {
          let allApplicatonsInst = [];
          let allApplicatonsInstData = {};
          const domainConfig = yield select(getDomainConfigData); // <-- get reducer data
          const {dashboard : {myApplication: { showOnlyCurrentUkcCard } = {}} = {}} = domainConfig || {};
          if(showOnlyCurrentUkcCard && checkIfArray(allApplicationsData)) {
            const accessToken = OfflineStorage.getItem(
              STORAGE_KEY.ACCESS_TOKEN,
              STORAGE_TYPE.LOCAL
            );
            const tokenDecoded = jwt_decode(accessToken);
            const ukcValue = tokenDecoded
              ? tokenDecoded[
                  ACCESS_TOKEN_CLAIMS[STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]
                ]
              : null;
            const entity = EntityFieldNameByCriteriaValue[uniqueKeyCriteria];
            if (ukcValue) {
              const foundUser = allApplicationsData.find((userDetail) => {
                if (
                  userDetail.lead &&
                  checkIfArray(userDetail.lead[entity]) &&
                  `${userDetail.lead[entity][0]}` === `${ukcValue}`
                ) {
                  return true;
                }
              });
              allApplicationsData = [];
              if(foundUser) {
                allApplicationsData.push(foundUser);
              }
            }
          }
          if(applicationFormCriteria) {
            checkIfArray(allApplicationsData) && allApplicationsData.forEach((userDetail, index) => {
              const applicationFormCriteriaValue = get(userDetail, `lead.${EntityFieldNameByCriteriaValue[applicationFormCriteria]}`)
              if(applicationFormCriteriaValue) {
                allApplicatonsInst.push(...applicationFormCriteriaValue);
                const updatedApplicationFormCriteriaValue = checkIfArray(applicationFormCriteriaValue) ? applicationFormCriteriaValue[0] : applicationFormCriteriaValue;
                let instName = "";
                checkIfArray(masterData[applicationFormCriteria]) &&
                  masterData[applicationFormCriteria].some((masterObj) => {
                    if (masterObj.id === updatedApplicationFormCriteriaValue) {
                      allApplicatonsInstData = {
                        ...allApplicatonsInstData,
                        [masterObj.id]: masterObj
                      }
                      instName = masterObj.name;
                      return true;
                    }
                  });
                allApplicationsData[index] = {
                  ...allApplicationsData[index],
                  instValue: updatedApplicationFormCriteriaValue,
                  instName
                }
              }
            });
            yield put(getAllApplicationsConfigInit(uniq(allApplicatonsInst), allApplicationsData, allApplicatonsInstData));
          } else {
            yield put(getAllApplicationSuccess(allApplicationsData, allApplicatonsInstData));
          }
        } else {
          yield put(getAllApplicationFail());
        }
      }
    }
  } catch (error) {
    logError(error);
    yield put(getAllApplicationFail(error));
  }
}

export function* fetchConfigurationsForInst(inst) {
  const dashbaordConfigUrl = `${window.extraaedge.client.Blob_Storage_Client_URL}/appform_${inst}/resources/dashbaordConfiguration.json`;
  const formConfigUrl = `${window.extraaedge.client.Blob_Storage_Client_URL}/appform_${inst}/resources/formConfiguration.json`;
  const clientConfigUrl = `${window.extraaedge.client.Blob_Storage_Client_URL}/appform_${inst}/resources/clientConfiguration.json`;
  const multipleApiArray = [
    call(fetch, dashbaordConfigUrl),
    call(fetch, formConfigUrl),
    call(fetch, clientConfigUrl),
  ];
  Configuration.supportedLanguages.forEach(language => {
    const languageUrl = `${window.extraaedge.client.Blob_Storage_Client_URL}/appform_${inst}/lang/${Configuration.supportedLanguagesFileNames[language]}.json`;
    multipleApiArray.push(call(fetch, languageUrl));
  })
  try {
    const [dashboardConfig, formConfig, clientConfig,  ...languagesConfig] = yield all(multipleApiArray);
    const languageResponses = checkIfArray(languagesConfig) && languagesConfig.map(lang => call(() => lang.json()));
    const [dashboardData, formData, clientData, ...languagesData] = yield all([
      call(() => dashboardConfig.json()),
      call(() => formConfig.json()),
      call(() => clientConfig.json()),
      ...(languageResponses || [])
    ]);
    checkIfArray(languagesData) && languagesData.map((lang, index) => {
      const langFileName = Configuration.supportedLanguages[index];
      i18n.addResourceBundle(langFileName, `${inst}-${langFileName}`, lang, true);
    })
    return {
      dashboardConfig: dashboardData,
      formConfig: formData,
      clientConfig: clientData,
    };
  } catch (error) {
    // Handle any errors or return defaults as needed
    return {
      dashboardConfig: null,
      formConfig: null,
      clientConfig: null,
    };
  }
}

export function* getAllApplicationsConfig(action) {
  const allApplicatonsInst = get(action, "payload.data");
  const {allApplicationsData, allApplicatonsInstData} = get(action, "payload") || {};
  try {
    let instwiseConfigs = {};
    for (const inst of allApplicatonsInst) {
      const config = yield call(fetchConfigurationsForInst, inst);
      instwiseConfigs[inst] = config;
    }
    renderApp({langFilesLoaded:true});
    yield put(getAllApplicationsConfigSuccess(instwiseConfigs));
    yield put(getAllApplicationSuccess(allApplicationsData, allApplicatonsInstData));
  } catch (error) {
    // Handle errors if any of the API requests fail
    console.error('API request error:', error);
    yield put(getAllApplicationsConfigFail());
    yield put(getAllApplicationFail());
  }
}

export function* watchUpdateAllConfigurationReducer() {
  yield takeLatest(actionTypes.UPDATE_ALL_CONFIGURATIONS_ACCORDING_TO_INST, updateAllConfigurationReducer);
}

export function* updateAllConfigurationReducer(action) {
  const selectedInstwiseAllConfigs = get(action, "payload.data");
  if(checkIfObject(selectedInstwiseAllConfigs)) {
    const {dashboardConfig, formConfig, clientConfig} = selectedInstwiseAllConfigs || {};
    if(dashboardConfig) {
      yield put(configSuccess(dashboardConfig));
    }
    if(formConfig) {
      yield put(updateFormConfigSuccess(formConfig));
    }
    if(clientConfig) {
      yield put(updateClientInfo(clientConfig));
    }
    yield updateFormDataAccordingToInst(formConfig, clientConfig);
  }
}


export function* updateFormDataInUKCSaga() {
  const formConfig = yield select(getFormReducer);
  const formData = yield call(
    updateFormDataFromUserInfo,
    formConfig.data
  );
  yield put({
    type: FormAction.UPDATE_FORM_DATA,
    payload: {
      data: JSON.parse(JSON.stringify(formData))
    }
  });
  yield put({
    type: FormAction.UPDATE_FORM_INITIAL_DATA,
    payload: {
      data: Object.assign({}, JSON.parse(JSON.stringify(formData)))
    }
  });
}

export function* watchUpdateFormDataInUKC() {
  yield takeLatest(actionTypes.UPDATE_FORM_DATA_IN_UKC, updateFormDataInUKCSaga);
}

export function* getAndUpdateTokenInUKCSaga(action) {
  try {
    yield put(toggleGlobalLoader(true));
    const applicationsAuthDetailsMappedWithLeadId = yield select(
      getApplicationAuthDetailsMappedWithLeadId
    );
    const clientInfo = yield select(getClientInfoData);
    const masterData = yield select(getMasterDataReducer);
    const counselorDetails = yield select(getCounselorDetails);
    const isUkc = get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA);
    const {leadId, accessToken, onSuccessGetToken, history,ukc=null} = action.payload.data || {};
    if (
      !get(applicationsAuthDetailsMappedWithLeadId, leadId) &&
      clientInfo &&
      isUkc
    ) {
      let params = {};
      let userRoleId = get(clientInfo, "userRoleId");
      if(counselorDetails && counselorDetails.userRoleId) {
        userRoleId = counselorDetails.userRoleId
      }
      if (isAccessTokenTableKeyEnabled() && accessToken) {
        if (accessToken) {
          get(
            clientInfo,
            `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`
          ) &&
            set(
              params,
              "clientAlias",
              get(
                clientInfo,
                `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`
              ) || ""
            );
          params = {
            ...params,
            t:accessToken,
          };
        } else {
          notify(
            getLang("global.notification.accessTokenMissingInAllApplication"),
            {
              type: ToastType.ERROR,
              autoClose: true,
            }
          );
        }
      } else {
        !!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL) &&
        set(
          params,
          STORAGE_KEY.INST,
          OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
        );
        !!OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL) &&
          set(
            params,
            STORAGE_KEY.PRN,
            OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL)
          );
        !!OfflineStorage.getItem(
          STORAGE_KEY.COUNSELOR_ID,
          STORAGE_TYPE.LOCAL
        ) &&
          set(
            params,
            STORAGE_KEY.COUNSELOR_ID,
            OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL)
          );
        get(
          clientInfo,
          `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.CLIENT_ID}`
        ) &&
          set(
            params,
            `clientId`,
            get(
              clientInfo,
              `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.CLIENT_ID}`
            ) || ""
          );
        userRoleId && set(params, `userrole`, userRoleId);
        !!OfflineStorage.getItem(
          STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
          STORAGE_TYPE.LOCAL
        ) &&
          set(
            params,
            STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
            OfflineStorage.getItem(
              STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
              STORAGE_TYPE.LOCAL
            )
          );
      }
      
      const result = yield call(Services.validateLogin, params);
      const auth_details = result?.data;
      if (auth_details) {
        const accessToken = get(auth_details, "accesstoken") || "";

        if(accessToken) {
          onSuccessGetToken && onSuccessGetToken();
        }

        OfflineStorage.setItem(
          STORAGE_KEY.ACCESS_TOKEN,
          accessToken,
          STORAGE_TYPE.LOCAL
        );

        yield put(authDetailsSuccess(auth_details));

        if (accessToken) {
          const tokenDecoded = jwt_decode(accessToken);
          const leadId = tokenDecoded[ACCESS_TOKEN_LEAD_KEY];
          if (leadId) {
            yield put(
              updateApplicationsAuthDetailsAccordingToLeadId({
                [leadId]: accessToken,
              })
            );
          }
        }
      } else {
        logoutApplicationForm({history, clientInfo, logout: logout})
      }
    } else {
      const accessToken = get(applicationsAuthDetailsMappedWithLeadId, leadId);
      if(isUkc && accessToken) {
        OfflineStorage.setItem(
          STORAGE_KEY.ACCESS_TOKEN,
          accessToken,
          STORAGE_TYPE.LOCAL
        );
        yield put(authDetailsSuccess({
          accesstoken: accessToken
        }));
      }
      onSuccessGetToken();
    }
    yield put(toggleGlobalLoader(false));
  } catch (error) {
    logError(error);
    yield put(toggleGlobalLoader(false));
  }
}

export function* watchGetAndUpdateTokenInUKC() {
  yield takeLatest(
    actionTypes.GET_AND_UPDATE_TOKEN_IN_UKC,
    getAndUpdateTokenInUKCSaga
  );
}

export function* createAnchorTagSaga(url, target = '', className = '', isClicked) {
  yield call(createAnchorTag, url, target, className, isClicked);
}

export function* getLeadDocumentsSaga(action) {
  const { leadId } = action.payload.data || {};
  try {
    yield put(getLeadDocumentsRequested(leadId));
    yield delay(get(window, "extraaedge.DELAY_TO_CALL_API.getLeadDocumentsAPI"));
    const result = yield call(Services.callGetLeadDocumentsAPI, leadId);
    const { data } = result || {};
    if(checkIfObject(data)) {
      const { pdf } = data;
      if (pdf && getMediaLiveURL(pdf)) {
        yield call(createAnchorTagSaga, getMediaLiveURL(pdf), "", "", true);
      }
      yield put(getLeadDocumentsSuccess(leadId));
    } else {
      yield put(getLeadDocumentsFail(leadId));
      logError(result);
    }
  } catch (error) {
    yield put(getLeadDocumentsFail(leadId));
    logError(error);
  }
 }

export function* watchGetLeadDocuments() {
  yield takeEvery(
    actionTypes.GET_LEAD_DOCUMENTS,
    getLeadDocumentsSaga
  );
}

export function* updateIndexMetaDataInAllApplicationSaga  (action) {
  yield put(updateIndexMetaDataInAllApplicationSuccess(action.payload.data))
}

export function* watchUpdateIndexMetaDataInAllApplication() {
  yield takeEvery(
    actionTypes.UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION,
    updateIndexMetaDataInAllApplicationSaga
  );
}
