import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import GenricModal from "../GenericModal";
import "react-phone-input-2/lib/style.css";
import "./widgetPopup.scss";
import {
  getWidgetFormConfig,
  webhookAddLead,
} from "../../../pages/allApplications/Actions";
import {
  cloneDeep,
  filter,
  find,
  get,
  intersection,
  isBoolean,
  isEmpty,
  isNil,
  map,
  omit,
  uniq,
} from "lodash";
import {
  checkIfArray,
  checkIfObject,
  filterMasterDataByPricingCriteria,
  getApplicationFormCriteriaValue,
  getDefaultCountryCode,
  getEntityIdBasedOnUKC,
  getErrorMessageForExistAPI,
  getLang,
  getMasterDataByKey,
  getUKCDetails,
  getUniqueKeyCriteriaValue,
  isItemIncluded,
  isValidMobileNumber,
  leadObjectManager,
  openApplicationForm,
  replaceEmailWithStars,
  replaceMobileWithStars,
  replaceToken,
  replaceUniqueCheckFieldValueWithStars,
  sortOptions,
  validateByType,
} from "../../utilties/Utils";
import {
  API_KEY_NAME,
  DEFAULT_COUNTRY_CODE,
  EMAIL_AND_MOBILE_VERIFICATION_ERRORS,
  FORM_INPUT_PATTERN,
  FORM_INPUT_UI,
  FORM_LAYOUT_PARAMETERS_KEYS,
  MOBILE_REGEX_COUNTRY_WISE,
  PAGES,
  REGEX,
  STORAGE_KEY,
  STORAGE_TYPE,
  VALIDATION_ERROR,
  VERIFICATION_AND_EDIT_ACTION_ITEMS,
  VERIFICATION_KEY_IDS,
  WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY,
} from "../../utilties/Constants";
import CustomText from "../formControls/CustomText";
import { Button, FormFeedback, FormGroup, Label } from "reactstrap";
import PhoneInput from "react-phone-input-2";
import CustomCheckBox from "../formControls/CustomCheckBox";
import Select from "react-select";
import {
  getAndUpdateTokenInUKC,
  updateAllConfigurationsAccordingToInst,
  updateFormDataInUkc,
  userDetailSuccess,
} from "../../services/appData/Actions";
import { OfflineStorage } from "../../utilties/OfflineStorage";
import i18n from "../../../infrastructure/localization/localizationservice";
import { Configuration } from "../../../infrastructure/configuration/appsettings";
import { Service } from "../../../pages/form/Services";
import VerificationModalPopup from "../VerificationModalPopup";
import { setShowVerifyModal } from "../../../pages/form/Actions";
const WidgetPopup = (props) => {
  const {
    masterData,
    domainConfig,
    clientInfo,
    isCounselorAccessActive,
    getWidgetFormConfig,
    widgetFormConfig,
    fetchWidgetFormConfigIsInProgress,
    userDetailSuccess,
    updateAllConfigurationsAccordingToInst,
    updateFormDataInUkc,
    getAndUpdateTokenInUKC,
    dashboardConfig,
    formConfig,
    history,
    instwiseAllConfigs,
    webhookAddLead,
    webHookAddLeadInProgress,
    userDetail,
    formData,
    allApplicationsData,
    isAllApplicationDataAPICalled,
    fetchAllApplicationDataIsinProgress,
    isAllApplicationDataFailed,
    showVerifyModal,
    setShowVerifyModal
  } = props || {};

  let { widgetPopup: widgetPopupConfig, defaultCountryCode = {}, widgetPopup : { disableFieldsOnValue = {} } = {}} = domainConfig || {};
  let { isoCode, countryCode: configCountryCode } = defaultCountryCode || {};
  let {
    isActive,
    modalTitle,
    submitBtnLabel,
    widgetPath,
    fields = {},
    note,
    defaultAttributesByInputType = {}
  } = widgetPopupConfig || {};
  let {
    config: { isPricingCriteriaActive } = {},
    apiRequestDefaultParameter,
    fields: widgetFormFields,
    otp : widgetOtpConfig,
    Mobile2_DefaultParamters: {
      defaultProps: { initialCountry = isoCode, preferredCountries, defaultCountryCodeNumber= configCountryCode, defaultValue: deafaultMobileNumber = ""} = {},
    } = {},
    validation: {
      message: {
        invalid: inValidMessage = "Enter valid $fieldName$.",
        required: requiredMessage = "$fieldName$ is mandatory.",
      } = {},
    } = {},
  } = widgetFormConfig || {};
  
  const { enableMasking = {} } = clientInfo || {};
  const { visible: isNotVisble } = note || {};
  const ukc = get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA);
  const [showWidgetPopup, setShowWidgetPopup] = useState(false);
  const [isInProgress, setIsInProgress] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [countryCode, setCountryCode] = useState({});
  const [countryCodeNumber, setCountryCodeNumber] = useState("");
  const [visibleFieldKeys, setVisibleFieldKeys] = useState([]);
  const [isMobileVerified, setIsMobileVerified] = useState(false);
  const [isMobileOTPCalled, setIsMobileOTPCalled] = useState(false);
  const [sendOtpLoader, setSendOtpLoader] = useState(false);
  const [ukcValue, setUkcValue] = useState(0);
  const [pricingCriterionEntityNumber, setPricingCriterionEntityNumber] =
    useState(
      masterData && get(masterData, `${STORAGE_KEY.PRICING_CRITERIA}`)
        ? masterData[STORAGE_KEY.PRICING_CRITERIA].replace(/[^0-9]/g, "")
        : null
    );
  const [verificationDataArray, setVerificationDataArray] = useState([]);
  const [sendOTPTrigger, setSendOTPTrigger] = useState("");
  const [
    applicationFormCriteriaEntityNumber,
    setApplicationFormCriteriaEntityNumber,
  ] = useState(
    masterData && get(masterData, `${STORAGE_KEY.APPLICATION_FORM_CRIETRIA}`)
      ? masterData[STORAGE_KEY.PRICING_CRITERIA].replace(/[^0-9]/g, "")
      : null
  );
  const CRITERIA_FIELDS_API_KEYS = [
    "ArrE1",
    "ArrE2",
    "ArrE3",
    "ArrE4",
    "ArrE1Ids",
    "ArrE2Ids",
    "ArrE3Ids",
    "ArrE4Ids",
  ];
  const dropdownOptionsRef = useRef({});
  const resetDropdownArray = useRef([]);
  const resetDropdownValuesRef = useRef({});
  const conditionalShowFields = useRef([]);
  const conditionalDependencies = useRef([]);
  const conditionalHideFields = useRef([]);
  const onSelectShowFields = useRef([]);
  const onSelectHideFields = useRef([]);
  const onSelectDependencies = useRef([]);
  const allVisbleAndMandatoryFieldsWithApiKey = useRef({});
  const oldValues = useRef({});
  const isAlredyExistAPIIsInpregress = useRef([]);
  const isSaveBtnClicked = useRef(false);
  const disabledFieldsWithEmptyData = useRef({});
  const isMobileNumberVerified = useRef(false);
  const isMobileNumberNotValidRef = useRef(false);
  const showWidgetPopupOnlyForMobileVerified = useRef(false);
  const isMobileVerificationChecked = useRef(false);
  const otpErrorValidatonMessage = getLang("global.validationMessage.OtpVerificationPending", "Otp verification is pending");


  const getUKCValues = (data) => {
    const newUKCValue = ukc && get(data, `${ukc}`);
    if(checkIfArray(newUKCValue)){
      return (newUKCValue[0].id || 0);
    }else if(checkIfObject(newUKCValue)){
      return newUKCValue.id || 0;
    }else {
      return 0;
    }
  }

  const isMobileNumberEmpty = useRef(false);
  const inst =
    isActive &&
    userDetail &&
    (get(userDetail, `instValue`) ||
      getApplicationFormCriteriaValue(userDetail, masterData));

  const { formConfig: instFormConfig } =
    (inst && instwiseAllConfigs && instwiseAllConfigs[inst]) || {};

  
  useEffect(() => {
    if(!!userDetail){
      const {lead : { eeSourceId = 0, verificationData = [] } = {}, user : {mobileNumber = ""} = ""} = userDetail || {};
      const {sourceIdsForOTPVerification : {includeIds = [7], excludeIds = []} = {}} = widgetPopupConfig || {};
      let verifyOtpVerificationFromLeadData = false; 
      const {mobile = ""} = data || {};
      if( mobile && checkIfArray(verificationDataArray) && verificationDataArray.includes(mobile)){
        setIsMobileVerified(true);
      } 
      else {
        let verificationFlag = false;
      // function to iterate through sourceId i.e array and set skipOTPVerificationFromVerifiedLeads
      // skipOTPVerificationFromVerifiedLeads -> to skip the send OTP Verification for some specific leadSources that are in excludes Array
        if(isItemIncluded(eeSourceId, excludeIds, includeIds)){
          verifyOtpVerificationFromLeadData = true
        } else {
          verifyOtpVerificationFromLeadData = false;
        }
        checkIfArray(verificationData)  && verificationData.forEach((verifiedData)  => {
          const {isVerified, keyId, value} = verifiedData || {};
          if(verifyOtpVerificationFromLeadData && (isVerified &&  keyId === VERIFICATION_KEY_IDS.PRIMARY_MOBILE_NUMBER && mobileNumber && value === mobile)){
                verificationFlag = true;
                setIsMobileVerified(true);
            } else if(!verificationFlag) {
                verificationFlag = false;
            }
        });
        !verificationFlag && setIsMobileVerified(false);
      }
    }
  },[data && data["mobile"], userDetail, verificationDataArray]);

  useEffect(() => {
    if (
      isActive &&
      !fetchWidgetFormConfigIsInProgress &&
      !widgetFormConfig &&
      userDetail &&
      widgetPath
    ) {
      const crm_alias = get(clientInfo, "Info.crm_alias");
      if (crm_alias) {
        getWidgetFormConfig(
          `${window.extraaedge.client.Widget_Blob_Storage_Client_URL}/${crm_alias}/${widgetPath}`
        );
      }
    }
  }, [
    clientInfo,
    userDetail,
    domainConfig,
    fetchWidgetFormConfigIsInProgress,
    allApplicationsData,
    widgetFormConfig,
  ]);

  useEffect(() => {
    checkMobileVerification();
  }, [data && data[API_KEY_NAME.MOBILE], userDetail, verificationDataArray, allApplicationsData]);

  useEffect(()=>{
    setUkcValue(getUKCValues(data) || 0);
  }, [data, ukc]);

  const checkMobileVerification = (isCalledBeforeWidgetConfigLoad) => {
    if (!get(widgetOtpConfig, "visible")) return false;
    let isMobileVerified = false;
    if (ukc && !isMobileVerified) {
      if (isAllApplicationDataAPICalled && checkIfArray(allApplicationsData)) {
        allApplicationsData.some((applicationData) => {
          const isMobileNumberVerified = updatedMobileVerification(
            applicationData,
            isCalledBeforeWidgetConfigLoad
          );
          if (isMobileNumberVerified) {
            isMobileVerified = true;
            return true;
          }
        })
      }else if(isAllApplicationDataAPICalled){
        isMobileVerified = updatedMobileVerification(
          userDetail,
          isCalledBeforeWidgetConfigLoad
        );
      }
    } else {
      isMobileVerified = updatedMobileVerification(
        userDetail,
        isCalledBeforeWidgetConfigLoad
      );
    }
    return isMobileVerified;
  };

  const updatedMobileVerification = (userDetail, isCalledBeforeWidgetConfigLoad = false) => {
    if (!!userDetail) {
      const {
        lead: { eeSourceId = 0, verificationData = [] } = {},
        user: { mobileNumber = "" } = "",
      } = userDetail || {};
      const {
        sourceIdsForOTPVerification: { includeIds = [7], excludeIds = [] } = {},
      } = widgetPopupConfig || {};
      let verifyOtpVerificationFromLeadData = false;
      const { mobile = "" } = data || {};
      if (
        mobile &&
        checkIfArray(verificationDataArray) &&
        verificationDataArray.includes(mobile)
      ) {
        setIsMobileVerified(true);
        return true;
      } else {
        let verificationFlag = false;
        isMobileVerificationChecked.current = true;
        const updatedMobile = mobile || mobileNumber;
        // function to iterate through sourceId i.e array and set skipOTPVerificationFromVerifiedLeads
        // skipOTPVerificationFromVerifiedLeads -> to skip the send OTP Verification for some specific leadSources that are in excludes Array
        if (isItemIncluded(eeSourceId, excludeIds, includeIds)) {
          verifyOtpVerificationFromLeadData = true;
        } else {
          verifyOtpVerificationFromLeadData = false;
        }
        checkIfArray(verificationData) &&
        verificationData.forEach((verifiedData) => {
          const { isVerified, keyId, value } = verifiedData || {};
          if (
            (isCalledBeforeWidgetConfigLoad ? true : verifyOtpVerificationFromLeadData) &&
            isVerified &&
            keyId === VERIFICATION_KEY_IDS.PRIMARY_MOBILE_NUMBER &&
            mobileNumber &&
            (value === updatedMobile)
          ) {
            verificationFlag = true;
            setIsMobileVerified(true);
          } else if (!verificationFlag) {
            verificationFlag = false;
          }
        });
        !verificationFlag && setIsMobileVerified(false);
        return verificationFlag;
      }
    }
  }

  const getMasterObject = (
    data,
    dataCollection,
    dataCollectionFilterOptions
  ) => {
    //Return array of master object or single object
    const masterDataCollection = getMasterDataByKey(
      dataCollection,
      masterData,
      dataCollectionFilterOptions
    );
    // Check if data and masterDataCollection are valid arrays
    if (data && checkIfArray(masterDataCollection)) {
      // If data is an array, filter and return matching master objects
      if (checkIfArray(data)) {
        const updatedData = [];
        data.forEach((masterId) => {
          const masterObject = masterDataCollection.find(
            (obj) => obj.id === masterId
          );
          if (masterObject) {
            updatedData.push(masterObject);
          }
        });
        return checkIfArray(updatedData) ? updatedData : null;
      } else {
        // If data is a single value, return the matching master object
        return masterDataCollection.find((obj) => obj.id === data);
      }
    }
    // Return data if no valid data or masterDataCollection
    return data;
  };

  const convertIdToDropdownObject = (
    data,
    conditional,
    dataCollection,
    dataCollectionFilterOptions
  ) => {
    // Iterate through each condition in the conditional array
    checkIfArray(conditional) &&
      conditional.forEach((condition) => {
        const { onSelectIds, showFields, dependencies, hideFields } =
          condition || {};
        // Update arrays with showFields and dependencies
        conditionalShowFields.current.push(...(showFields || []));
        conditionalDependencies.current.push(...(dependencies || []));
        conditionalHideFields.current.push(...(hideFields || []));

        // Check if there is a common element between data and onSelectIds
        const hasCommonElement = !isEmpty(
          intersection(checkIfArray(data) ? data : [data], onSelectIds)
        );

        // If there is a common element, update showFields and dependencies arrays
        if (hasCommonElement) {
          checkIfArray(showFields) &&
            onSelectShowFields.current.push(...showFields);
          checkIfArray(hideFields) &&
            onSelectHideFields.current.push(...hideFields);
          checkIfArray(dependencies) &&
            onSelectDependencies.current.push(...dependencies);
        }
      });

    // Remove duplicate elements from the arrays
    onSelectShowFields.current = uniq(onSelectShowFields.current);
    onSelectHideFields.current = uniq(onSelectHideFields.current);
    onSelectDependencies.current = uniq(onSelectDependencies.current);

    conditionalShowFields.current = uniq(conditionalShowFields.current);
    conditionalHideFields.current = uniq(conditionalHideFields.current);
    conditionalDependencies.current = uniq(conditionalDependencies.current);

    // Return the dropdown object(s) using the getMasterObject function
    return getMasterObject(data, dataCollection, dataCollectionFilterOptions);
  };

  useEffect(() => {
    const visibleFieldKeysArray = [];
    const updatedMobileVerified = !isMobileVerified ? checkMobileVerification(true) : true;
    if (
      isActive &&
      widgetFormConfig &&
      checkIfObject(widgetFormFields) &&
      checkIfObject(userDetail) &&
      (!updatedMobileVerified ? true : !get(userDetail, "lead.applicationFormNumber"))
      && !isMobileOTPCalled
    ) {
      getUKCValues(get(userDetail,"lead") || {});
      let defaultData = {};
      let isRequiredToShowWidgetPopup = false;
      Object.keys(widgetFormFields).map((fieldKey) => {
        let {
          visible,
          required,
          apiKey,
          defaultValue,
          type,
          dataCollection,
          dataCollectionFilterOptions,
          countryCode: { defaultValue: countryCodeDefaultValue, visible: countryCodeVisible } = {},
          ref: { conditional } = {},
        } = widgetFormFields[fieldKey];
        if (visible) {
          let dataDetails = get(data, fieldKey);
          if(checkIfObject(dataDetails) && dataDetails.id){
            dataDetails = dataDetails.id;
          }
          let leadData =
            (WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey] &&
            get(
              userDetail,
              WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey]
            )) ||  dataDetails;
          const isAgreeCheckBox =
            type === FORM_INPUT_UI.CHECKBOX && fieldKey === "iAgree";
          if (
            required &&
            checkIfArray(conditionalShowFields.current) &&
            conditionalShowFields.current.includes(fieldKey) &&
            !(
              checkIfArray(onSelectShowFields.current) &&
              onSelectShowFields.current.includes(fieldKey)
            )
          ) {
            required = false;
          }

          if (
            required &&
            !leadData &&
            !isRequiredToShowWidgetPopup &&
            WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey] &&
            !isAgreeCheckBox
          ) {
            isRequiredToShowWidgetPopup = true;
          }
          if (leadData) {
            if (
              type === FORM_INPUT_UI.SELECT ||
              type === FORM_INPUT_UI.HIDDEN
            ) {
              leadData = convertIdToDropdownObject(
                leadData,
                conditional,
                dataCollection,
                dataCollectionFilterOptions
              );
            }else if(
              type ===  FORM_INPUT_UI.TEL || 
              type === FORM_INPUT_UI.MOBILE2){
                const updatedInitialCountry =  initialCountry === "in" ? leadData?.length === 10 ? "in" : "" : initialCountry;
                leadData = getDefaultCountryCode(leadData, fieldKey, deafaultMobileNumber, countryCode, updatedInitialCountry, ((countryCodeVisible && countryCodeDefaultValue) || defaultCountryCodeNumber), countryCodeNumber);
            }
          } else if (defaultValue) {
            if (
              type === FORM_INPUT_UI.SELECT ||
              type === FORM_INPUT_UI.HIDDEN
            ) {
              leadData = convertIdToDropdownObject(
                defaultValue,
                conditional,
                dataCollection,
                dataCollectionFilterOptions
              );
            }
          }
          leadData &&
            (defaultData = {
              ...defaultData,
              [fieldKey]: leadData,
            });
          visibleFieldKeysArray.push(fieldKey);
        }
      });
      if (
        !isRequiredToShowWidgetPopup &&
        checkIfArray(onSelectDependencies.current)
      ) {
        onSelectDependencies.current.some((fieldKey) => {
          const { apiKey } = widgetFormFields[fieldKey] || {};
          let leadData =
            WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey] &&
            get(
              userDetail,
              WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey]
            );
          if (
            !leadData &&
            WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey]
          ) {
            isRequiredToShowWidgetPopup = true;
            return true;
          }
        });
      }
      showWidgetPopupOnlyForMobileVerified.current =  !isRequiredToShowWidgetPopup && !!get(widgetOtpConfig, "visible") && isMobileVerificationChecked.current && (!updatedMobileVerified);
      if (isRequiredToShowWidgetPopup || showWidgetPopupOnlyForMobileVerified.current) {
        setShowWidgetPopup(true);
        checkIfObject(defaultData) && setData(defaultData);
        setVisibleFieldKeys(visibleFieldKeysArray);
      }
    }
  }, [widgetFormConfig, userDetail, isMobileVerified]);

  useEffect(() => {
    if (
      isActive &&
      masterData &&
      checkIfArray(visibleFieldKeys) &&
      checkIfObject(userDetail) &&
      !get(userDetail, "lead.applicationFormNumber")
    ) {
      const { dropdownOptions } = filterMasterDataByPricingCriteria({
        masterData,
        isPricingCriteriaActive,
        visibleFieldKeys,
        widgetFormFields,
        fields,
      });
      dropdownOptionsRef.current = dropdownOptions || {};
    }
    if (
      pricingCriterionEntityNumber &&
      masterData &&
      !!masterData[STORAGE_KEY.PRICING_CRITERIA] &&
      pricingCriterionEntityNumber !== masterData[STORAGE_KEY.PRICING_CRITERIA]
    ) {
      setPricingCriterionEntityNumber(
        masterData[STORAGE_KEY.PRICING_CRITERIA].replace(/[^0-9]/g, "")
      );
    }
    if (
      applicationFormCriteriaEntityNumber &&
      masterData &&
      !!masterData[STORAGE_KEY.APPLICATION_FORM_CRIETRIA] &&
      applicationFormCriteriaEntityNumber !==
        masterData[STORAGE_KEY.APPLICATION_FORM_CRIETRIA]
    ) {
      setApplicationFormCriteriaEntityNumber(
        masterData[STORAGE_KEY.APPLICATION_FORM_CRIETRIA].replace(/[^0-9]/g, "")
      );
    }
  }, [masterData, visibleFieldKeys, userDetail]);

  useEffect(() => {
    if (isSaveBtnClicked.current && checkIfObject(error)) {
      isSaveBtnClicked.current = false;
      let firstErrorFieldDiv =
        document.getElementsByClassName("input-validation-error-messsage ") &&
        document.getElementsByClassName("input-validation-error-messsage ")[0];
      firstErrorFieldDiv = firstErrorFieldDiv && firstErrorFieldDiv.parentNode;
      if (firstErrorFieldDiv) {
        firstErrorFieldDiv.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [error]);

  useEffect(()=>{
    if(isMobileVerified && get(error, API_KEY_NAME.MOBILE_OTP) === otpErrorValidatonMessage){
      const errorObj = {...error};
      delete errorObj[API_KEY_NAME.MOBILE_OTP];
      setError(errorObj)
    }
  },[isMobileVerified])

  const onTextChange = (fieldId, value) => {
    if (get(error, fieldId)) {
      setError(omit(error, fieldId));
    }
    setData((currentData) => ({
      ...currentData,
      [fieldId]: !!value ? value : "",
    }));
  };

  const removeIdFromApiInProgress = (id) => {
    const index = isAlredyExistAPIIsInpregress.current.indexOf(id);
    if (index > -1) {
      // only splice array when item is found
      isAlredyExistAPIIsInpregress.current.splice(index, 1);
    }
  };

  const checkIfEmailMobileExists = async (id, params, apiEndpoint, value, fromSendOTP) => {
    const { type, label } = params || {};
    try {
      !isAlredyExistAPIIsInpregress.current.includes(id) &&
        isAlredyExistAPIIsInpregress.current.push(id);
      await Service.verifyIfEmailMobileExists(apiEndpoint)
        .then((res) => {
          removeIdFromApiInProgress(id);
          const {data = null} = res || {};
          if(isBoolean(data)){
            if (data) {
              let message = replaceToken({
                sourceStr: getLang(
                  type === FORM_INPUT_UI.EMAIL
                    ? VALIDATION_ERROR.EMAIL_EXISTS_IN_OTHER_LEAD.errorMessage
                    : VALIDATION_ERROR.MOBILE_EXISTS_IN_OTHER_LEAD.errorMessage
                ),
                name: getLang(label),
                value: value,
              });
              setSendOtpLoader(false);
              setError({
                ...error,
                [id]: message,
              });
            }else if(fromSendOTP){
                showSendOTPModal();
                setSendOtpLoader(false);
            }
          }
        })
        .catch((e) => {
          setError({
            ...(error || {}),
            [id]: getErrorMessageForExistAPI(e),
          });
        });
    } catch (error) {
      setError({
        ...(error || {}),
        [id]: getErrorMessageForExistAPI(error),
      });
      console.log(`${apiEndpoint} ERROR - ${error}`);
    } finally{
      removeIdFromApiInProgress(id);
      setSendOtpLoader(false);
    }
  };

  const onMobile2Change = (value, countryCodeDetails, fieldId, fieldConfig) => {
    const {skipCountryCodeWiseValidation=false} = fieldConfig;
    if (get(error, fieldId)) {
      setError(omit(error, fieldId));
    }
    let { countryCode: newCountryCode, dialCode="" } = countryCodeDetails || {};
    newCountryCode = newCountryCode?.toUpperCase();
    if(countryCode?.[fieldId] !== newCountryCode){      
      onBlurHandle(fieldId, value, fieldConfig, countryCodeDetails);
      oldValues.current = {
        ...oldValues.current,
        [fieldId]: value,
      };
    }
    if(fieldId === API_KEY_NAME.MOBILE){
      const isValid =  skipCountryCodeWiseValidation || isValidMobileNumber(value, newCountryCode, dialCode);
      isMobileNumberEmpty.current = value === dialCode || isEmpty(value); 
      isMobileNumberNotValidRef.current = !isValid;
    }
    newCountryCode &&
      setCountryCode((currentData) => ({
        ...currentData,
        [fieldId]: newCountryCode,
      }));
    dialCode &&
      setCountryCodeNumber((currentData) => ({
        ...currentData,
        [fieldId]: dialCode,
      }));
    setData((currentData) => ({
      ...currentData,
      [fieldId]: !!value ? value.replace(/[^0-9]+/g, "") : "",
    }));    
  };

  const onBlurHandle = async (id, event, fieldConfig, countryCodeDetails) => {
    const value = event?.target?.value || event;
    let {
      type,
      pattern,
      label,
      countryCode: { visible } = {},
      uniqueValue,
      skipCountryCodeWiseValidation=false
    } = fieldConfig || {};
    pattern = pattern || FORM_INPUT_PATTERN[type];
    let isValid = true;
    const { defaultProps: { checkIfMobileExists, checkIfEmailExists } = {} } =
      instFormConfig || formConfig || {};
    let newCountryCode = countryCodeDetails?.countryCode;
    if (
      ((pattern && !validateByType(value, type, pattern)) ||
        type === FORM_INPUT_UI.TEL) &&
      oldValues.current[id] !== value
    ) {
      isValid = false; //This line of code should be at the top of if condition
      if(skipCountryCodeWiseValidation){
        isValid = true;
      }else if (type === FORM_INPUT_UI.TEL && visible && newCountryCode) {
        let { dialCode } = countryCodeDetails || {};
        const inputValWithoutCountryCode = dialCode && value?.replace(dialCode, "");
        newCountryCode = newCountryCode && newCountryCode.toUpperCase();
        newCountryCode && 
        setCountryCode((currentData) => ({
          ...currentData,
          [id]: newCountryCode,
        }));
        dialCode &&
        setCountryCodeNumber((currentData) => ({
          ...currentData,
          [id]: dialCode,
        }));
        const selectedCountryRegex =
          MOBILE_REGEX_COUNTRY_WISE[newCountryCode.toUpperCase()];
        isValid =
          value && selectedCountryRegex && selectedCountryRegex.test(value);
        isValid = (!isValid || selectedCountryRegex?.toString()?.includes(')?'))
          ? inputValWithoutCountryCode
            ? selectedCountryRegex &&
              selectedCountryRegex.test(inputValWithoutCountryCode)
            : isValid
          : isValid;
      }
      if (!isValid) {
        let errorObj = {
          ...error,
          [id]: inValidMessage.replace("$fieldName$", label),
        };
        setError(errorObj);
      }
    }
    if (isValid && oldValues.current[id] && oldValues.current[id] !== value) {
      const entityId = ukcValue || getEntityIdBasedOnUKC() || 0;
      const isAPIParamsPresent =
        !isEmpty(value) &&
        get(userDetail, "lead.userId") &&
        get(userDetail, "lead.id");
      // API call to check if Email Id already exists
      if (
        type === FORM_INPUT_UI.EMAIL &&
        get(window, "extraaedge.API_END_POINT.check_Mobile_Exists") &&
        isAPIParamsPresent &&
        checkIfEmailExists
      ) {
        let apiEndpoint = `${get(
          window,
          "extraaedge.API_END_POINT.check_Email_Exists"
        )}?email=${value}&entityId=${entityId}&isExactMatch=true&userId=${
          userDetail.lead.userId
        }&leadId=${userDetail.lead.id}`;
        await checkIfEmailMobileExists(id, fieldConfig, apiEndpoint, value);
      }
      // API call to check if Mobile Number already exists
      else if (
        [
          FORM_INPUT_UI.MOBILE,
          FORM_INPUT_UI.TEL,
          FORM_INPUT_UI.MOBILE2,
        ].includes(type) &&
        get(window, "extraaedge.API_END_POINT.check_Mobile_Exists") &&
        isAPIParamsPresent &&
        checkIfMobileExists
      ) {
        if(get(widgetOtpConfig, `visible`) ? (id !== API_KEY_NAME.MOBILE) : true){
          let apiEndpoint = `${get(
            window,
            "extraaedge.API_END_POINT.check_Mobile_Exists"
          )}?mobileNumber=${value}&entityId=${entityId}&isExactMatch=true&userId=${
            userDetail.lead.userId
          }&leadId=${userDetail.lead.id}`;
          await checkIfEmailMobileExists(id, fieldConfig, apiEndpoint, value);
        }
      }
    }
  };

  const onFocusHandle = (id, event) => {
    oldValues.current = {
      ...oldValues.current,
      [id]: event?.target?.value || event,
    };
  };

  const getCollection = (fieldConfig) => {
    const { dataCollection, mapping, dataCollectionFilterOptions, sorting } =
      fieldConfig || {};
    let parentIds = null;
    if (
      get(mapping, `parent.fieldKey`) &&
      get(mapping, `parent.datakey`) &&
      get(data, mapping.parent.fieldKey)
    ) {
      const selectedParentFieldData = checkIfArray(
        data[mapping.parent.fieldKey]
      )
        ? data[mapping.parent.fieldKey]
        : [data[mapping.parent.fieldKey]];
      parentIds = map(selectedParentFieldData, `id`);
    }
    const masterArray =
      dropdownOptionsRef.current &&
      checkIfArray(dropdownOptionsRef.current[dataCollection])
        ? dropdownOptionsRef.current[dataCollection]
        : sortOptions(
            getMasterDataByKey(
              dataCollection,
              masterData,
              dataCollectionFilterOptions
            ),
            sorting
          );
    const filteredCollection = filter(masterArray, (item) => {
      return (
        isNil(get(mapping, `parent.datakey`)) ||
        (get(mapping, `parent.datakey`) &&
          !!item[mapping.parent.datakey] &&
          checkIfArray(parentIds) &&
          parentIds.includes(item[mapping.parent.datakey]))
      );
    });
    return filteredCollection;
  };

  const getResetDropdownValues = (fieldKey) => {
    const widgetFormFieldConfig = widgetFormFields[fieldKey];
    const fieldConfig = {
      ...widgetFormFieldConfig,
      ...fields[fieldKey],
    };
    const { mapping, visible } = fieldConfig || {};
    if (mapping?.reset && visible) {
      resetDropdownArray.current.push(mapping.reset);
      getResetDropdownValues(mapping.reset);
    }
  };

  const onChangeDropdown = (dropdownData, fieldConfig, dropdownId) => {
    const { mapping, ref: { conditional } = {} } = fieldConfig || {};
    const { parent, reset } = mapping || {};
    const selectedId = get(dropdownData, "id") || null;
    if (get(error, dropdownId)) {
      setError(omit(error, dropdownId));
    }
    let updatedDropdownData = {};

    const changeConditionalData = (widgetFormFields, fieldKey) => {
      const { ref: { conditional } = {} } = widgetFormFields[fieldKey] || {};
      checkIfArray(conditional) &&
        conditional.forEach((condition) => {
          const { onSelectIds, showFields, dependencies, hideFields } =
            condition || {};
          if (
            selectedId &&
            checkIfArray(onSelectIds) &&
            onSelectIds.includes(selectedId)
          ) {
            checkIfArray(showFields) &&
              onSelectShowFields.current.push(...showFields);
            checkIfArray(hideFields) &&
              onSelectHideFields.current.push(...hideFields);
            checkIfArray(dependencies) &&
              onSelectDependencies.current.push(...dependencies);
          } else {
            checkIfArray(showFields) &&
              showFields.forEach((fieldKey) => {
                const fieldKeyIndex =
                  onSelectShowFields.current.indexOf(fieldKey);
                if (fieldKeyIndex > -1) {
                  onSelectShowFields.current.splice(fieldKeyIndex, 1);
                }
                get(updatedDropdownData, fieldKey) &&
                  (updatedDropdownData = {
                    ...updatedDropdownData,
                    [fieldKey]: null,
                  });
              });
            checkIfArray(hideFields) &&
              hideFields.forEach((fieldKey) => {
                const fieldKeyIndex =
                  onSelectHideFields.current.indexOf(fieldKey);
                if (fieldKeyIndex > -1) {
                  onSelectHideFields.current.splice(fieldKeyIndex, 1);
                }
                get(updatedDropdownData, fieldKey) &&
                  (updatedDropdownData = {
                    ...updatedDropdownData,
                    [fieldKey]: null,
                  });
              });
            checkIfArray(dependencies) &&
              dependencies.forEach((fieldKey) => {
                const fieldKeyIndex =
                  onSelectDependencies.current.indexOf(fieldKey);
                if (fieldKeyIndex > -1) {
                  onSelectDependencies.current.splice(fieldKeyIndex, 1);
                }
                get(updatedDropdownData, fieldKey) &&
                  (updatedDropdownData = {
                    ...updatedDropdownData,
                    [fieldKey]: null,
                  });
              });
          }
          const showAndHideFields = [
            ...(showFields || []),
            ...(hideFields || []),
          ];
          isNil(selectedId) &&
            checkIfArray(showAndHideFields) &&
            showAndHideFields.forEach((key) => {
              changeConditionalData(widgetFormFields, key);
            });
        });
    };
    changeConditionalData(widgetFormFields, dropdownId);
    onSelectShowFields.current = uniq(onSelectShowFields.current);
    onSelectHideFields.current = uniq(onSelectHideFields.current);
    onSelectDependencies.current = uniq(onSelectDependencies.current);

    resetDropdownArray.current = [];
    if (reset) {
      if (checkIfArray(resetDropdownValuesRef.current[dropdownId])) {
        resetDropdownArray.current = resetDropdownValuesRef.current[dropdownId];
      } else {
        resetDropdownArray.current.push(mapping.reset);
        getResetDropdownValues(mapping.reset);
        resetDropdownValuesRef.current = {
          ...resetDropdownValuesRef.current,
          [dropdownId]: resetDropdownArray.current,
        };
      }
    }
    if (checkIfArray(resetDropdownArray.current)) {
      resetDropdownArray.current.forEach((key) => {
        updatedDropdownData = {
          ...updatedDropdownData,
          [key]: null,
        };
      });
    }
    setData((prevState) => ({
      ...prevState,
      ...updatedDropdownData,
      [dropdownId]: dropdownData,
    }));
  };

  const getTokenValue = (tokenConfig) => {
    const { dataFetchPath, masterDataCollectionName } = tokenConfig;
    const masterDataCollection =
      masterData && getMasterDataByKey(masterDataCollectionName, masterData);
    if (checkIfArray(masterDataCollection) && dataFetchPath) {
      let masterDataCollectionId = get(userDetail, `${dataFetchPath}`);
      masterDataCollectionId = checkIfArray(masterDataCollectionId)
        ? masterDataCollectionId[0]
        : masterDataCollectionId;
      const masterDataValue = find(masterDataCollection, {
        id: masterDataCollectionId,
      });
      if (checkIfObject(masterDataValue)) {
        return get(masterDataValue, `name`) || "";
      }
    } else if (dataFetchPath) {
      const tokenValue = get(userDetail, `${dataFetchPath}`);
      return tokenValue;
    }
    return ""; //If no token value found then return empty string
  };

  const renderNote = () => {
    const { content, cssClass, mobileVeriFicationContent } = note || {};
    const updatedContent = showWidgetPopupOnlyForMobileVerified.current ? mobileVeriFicationContent : content;
    if (updatedContent) {
      let cardContent = getLang(updatedContent, "", {
        namespace: inst
          ? `${inst}-${Configuration.activeLanguage}`
          : i18n.options.defaultNS,
      });
      const tokens = (
        (cardContent && cardContent.match(REGEX.TOKEN_REGEX1)) ||
        []
      ).map((match) => match.slice(1, -1));
      checkIfArray(tokens) &&
        uniq(tokens).forEach((token) => {
          const tokenConfig = note[token];
          if (checkIfObject(tokenConfig)) {
            let tokenValue = getTokenValue(tokenConfig);
            tokenValue = !isNil(tokenValue) ? tokenValue : "";
            cardContent = cardContent.replaceAll(`$${token}$`, tokenValue);
          }
        });
      return (
        <div
          className={`note ${cssClass ? cssClass : ""}`}
          dangerouslySetInnerHTML={{
            __html: cardContent,
          }}
        />
      );
    }
  };

  const renderModalBody = () => {
    return (
      <div className="widget-modal-container">
        {isNotVisble && renderNote()}
        {checkIfObject(widgetFormFields) &&
          Object.keys(widgetFormFields).map((fieldKey) => {
            let fieldConfig = widgetFormFields[fieldKey];
            let {
              visible,
              type,
              placeholder,
              label,
              maxLength,
              pattern,
              disabled,
              required,
              apiKey,
              multiple = false,
              description,
              countryCode,
              cssClass,
            } = fieldConfig || {};
            const defaultProps = !!type && checkIfObject(defaultAttributesByInputType) && defaultAttributesByInputType[type] ? defaultAttributesByInputType[type] :{};
            fieldConfig = {...fieldConfig,...defaultProps};
            if (onSelectDependencies.current.includes(fieldKey)) {
              required = true;
            }
            if (onSelectHideFields.current.includes(fieldKey)) {
              visible = false;
              required = false;
            }
            let isFieldVisible = visible && required;
            if (conditionalShowFields.current.includes(fieldKey)) {
              isFieldVisible = false;
            }
            if (onSelectShowFields.current.includes(fieldKey) && required) {
              isFieldVisible = true;
            }
            if (isFieldVisible && type) {
              if (type === FORM_INPUT_UI.TEL && !countryCode?.visible) {
                type = FORM_INPUT_UI.MOBILE;
                widgetFormFields[fieldKey] = {
                  ...widgetFormFields[fieldKey],
                  type: FORM_INPUT_UI.MOBILE,
                };
                fieldConfig = widgetFormFields[fieldKey];
              }
              const selectedValue =
                !!data && !!data[fieldKey] ? data[fieldKey] : "";
              if (
                disabled &&
                (isNil(selectedValue) || isEmpty(selectedValue))
              ) {
                disabled = false;
                disabledFieldsWithEmptyData.current = {
                  ...disabledFieldsWithEmptyData.current,
                  [fieldKey]: true,
                };
              } else if (disabledFieldsWithEmptyData.current[fieldKey]) {
                disabled = false;
              }
              switch (type) {
                case FORM_INPUT_UI.TEL:
                case FORM_INPUT_UI.MOBILE2:
                  allVisbleAndMandatoryFieldsWithApiKey.current = {
                    ...allVisbleAndMandatoryFieldsWithApiKey.current,
                    [fieldKey]: apiKey,
                  };
                  const mobileValue = data && data[fieldKey];
                  const mobileOTPError = API_KEY_NAME.MobileNumber === apiKey && get(widgetOtpConfig,`visible`) && get(error,API_KEY_NAME.MOBILE_OTP);
                  const newValue = getDefaultCountryCode(mobileValue, fieldKey, deafaultMobileNumber, countryCode, initialCountry, defaultCountryCodeNumber, countryCodeNumber);
                  const isMaskingValueEnabled = get(enableMasking, API_KEY_NAME.MOBILE) || false;
                  let maskingValue;
                  let inputMaskingValueObj = {};
                  if (isCounselorAccessActive && isMaskingValueEnabled) {
                    maskingValue = replaceMobileWithStars(newValue, true);
                    inputMaskingValueObj = {
                      alwaysDefaultMask: !!maskingValue,
                      defaultMask: maskingValue,
                    };
                  }
                  const showSendOtpButton = !!get(widgetOtpConfig, "visible") &&
                  apiKey === API_KEY_NAME.MobileNumber &&
                  fieldKey === API_KEY_NAME.MOBILE;
                  return (
                    <div className='col-12 col-md-6 form-group'>
                      <Label for={fieldKey}>
                        {label}
                        {required && (
                          <span className='required-notation'>{" *"}</span>
                        )}
                      </Label>
                      <div className={`d-flex input-mobile-container ${showSendOtpButton && !isMobileVerified && showWidgetPopupOnlyForMobileVerified.current ? "input-mobile-notverified" : ""}`}>
                        <PhoneInput
                          countryCodeEditable={false}
                          enableSearch={false}
                          country={countryCode[fieldKey] || (initialCountry && initialCountry.toLowerCase())}
                          value={newValue}
                          placeholder={placeholder}
                          specialLabel={""}
                          onChange={(value, countryCodeDetails) => {
                            onMobile2Change(
                              value,
                              countryCodeDetails,
                              fieldKey,
                              fieldConfig
                            );
                          }}
                          id={fieldKey}
                          key={fieldKey}
                          inputProps={{
                            name: fieldKey,
                            id: fieldKey,
                          }}
                          preferredCountries={preferredCountries}
                          onBlur={(e, countryCodeDetails) => {
                            onBlurHandle(fieldKey, data[fieldKey], fieldConfig, countryCodeDetails);
                          }}
                          onFocus={(e) => {
                            onFocusHandle(
                              fieldKey,
                              data[fieldKey],
                              fieldConfig
                            );
                          }}
                          disabled={!!get(inputMaskingValueObj, `alwaysDefaultMask`) || disabled}
                          {...inputMaskingValueObj}
                        />
                        {showSendOtpButton && (
                            <Button
                              disabled={
                                isMobileNumberEmpty.current || 
                                isMobileVerified ||
                                isMobileNumberNotValidRef.current ||
                                get(error, fieldKey) || isEmpty(mobileValue)
                              }
                              color='primary'
                              className={`${
                                sendOtpLoader ? "sendotp-loader" : ""
                              }`}
                              onClick={() => onSendOtp(fieldKey, fieldConfig)}
                            >
                              {sendOtpLoader ? (
                                <span className='loader-container'>
                                  <span className='loader'></span>
                                </span>
                              ) : (
                                <span className="sendotp-btn">
                                  {isMobileVerified
                                    ? get(
                                        widgetOtpConfig,
                                        `otp.buttonsLabels.label_on_verified_success`
                                      ) || "Verified"
                                    : get(
                                        widgetOtpConfig,
                                        `otp.sendOtp.label`
                                      ) || "Send OTP"}
                                </span>
                              )}
                            </Button>
                          )}
                      </div>
                      {error && (error[fieldKey] || mobileOTPError) && (
                        <FormFeedback
                          className={`input-validation-error-messsage`}
                        >
                          {error[fieldKey] || mobileOTPError}
                        </FormFeedback>
                      )}
                    </div>
                  );
                case FORM_INPUT_UI.MOBILE:
                case FORM_INPUT_UI.EMAIL:
                case FORM_INPUT_UI.TEXT:
                  allVisbleAndMandatoryFieldsWithApiKey.current = {
                    ...allVisbleAndMandatoryFieldsWithApiKey.current,
                    [fieldKey]: apiKey,
                  };
                  const selectedTextValue =  get(data, fieldKey) || "";
                    let updatedValue;
                    const {mobile = false, email = false} = enableMasking || {}
                    if (isCounselorAccessActive) {
                      if (type === FORM_INPUT_UI.MOBILE && mobile) {
                        updatedValue = replaceMobileWithStars(selectedTextValue);
                      }  else if (type === FORM_INPUT_UI.EMAIL && email) {
                        updatedValue = replaceEmailWithStars(selectedTextValue);
                      }
                    }
                  return (
                    <CustomText
                      customCssClass={cssClass}
                      key={fieldKey}
                      id={fieldKey}
                      fieldKey={fieldKey}
                      type={type}
                      cols={FORM_LAYOUT_PARAMETERS_KEYS.HALF_BLOCK}
                      visible={visible}
                      error={
                        !!error && !!error[fieldKey] ? error[fieldKey] : ""
                      }
                      value={updatedValue || selectedTextValue}
                      placeholder={placeholder}
                      label={label}
                      maxLength={maxLength}
                      pattern={pattern}
                      handleChange={onTextChange}
                      required={required}
                      apiKey={apiKey}
                      disabled={!!updatedValue || disabled}
                      labelStyle={{}}
                      fieldConfig={fieldConfig}
                      onBlur={onBlurHandle}
                      onFocus={onFocusHandle}
                      addAppformGroupClass={false}
                    />
                  );
                case FORM_INPUT_UI.CHECKBOX:
                  allVisbleAndMandatoryFieldsWithApiKey.current = {
                    ...allVisbleAndMandatoryFieldsWithApiKey.current,
                    [fieldKey]: apiKey,
                  };

                  return (
                    <CustomCheckBox
                      key={fieldKey}
                      id={fieldKey}
                      type={type}
                      cols={FORM_LAYOUT_PARAMETERS_KEYS.FULL_BLOCK}
                      visible={visible}
                      error={
                        !!error && !!error[fieldKey] ? error[fieldKey] : ""
                      }
                      value={!!data && !!data[fieldKey] ? data[fieldKey] : ""}
                      label={description}
                      handleChange={onTextChange}
                      required={required}
                    />
                  );
                  break;
                case FORM_INPUT_UI.SELECT:
                  const dropDownCollection = getCollection(
                    widgetFormFields[fieldKey]
                  );
                  const selectDropdownError =
                    !!error && !!error[fieldKey] ? error[fieldKey] : null;
                  let leadSelectedDropdownValue =
                    !!data && !!data[fieldKey] ? data[fieldKey] : null;
                  if (
                    !!leadSelectedDropdownValue &&
                    apiKey &&
                    CRITERIA_FIELDS_API_KEYS &&
                    CRITERIA_FIELDS_API_KEYS.includes(apiKey)
                  ) {
                    const entityNumber = apiKey.replace(/[^0-9]/g, "");
                    if (
                      !!entityNumber &&
                      (entityNumber !== applicationFormCriteriaEntityNumber ||
                        entityNumber === pricingCriterionEntityNumber)
                    ) {
                      if (checkIfArray(leadSelectedDropdownValue)) {
                        //mulitple values found against lead in userDetails
                        const updatedLeadSelectedDropdownValue =
                          leadSelectedDropdownValue.map((dropdownValue) => {
                            if (checkIfObject(dropdownValue)) {
                              return dropdownValue.id;
                            }
                            return dropdownValue;
                          });
                        leadSelectedDropdownValue = filter(
                          dropDownCollection,
                          (i) => {
                            return (
                              !!i.id &&
                              updatedLeadSelectedDropdownValue.includes(i.id)
                            );
                          }
                        );
                      } else {
                        //single value found against lead in userDetails
                        const dbValue = !!leadSelectedDropdownValue.id
                          ? find(dropDownCollection, (i) => {
                              return (
                                !!i.id && leadSelectedDropdownValue.id == i.id
                              );
                            })
                          : null;
                        if (!!dbValue && dbValue.id) {
                          leadSelectedDropdownValue = dbValue;
                        } else {
                          leadSelectedDropdownValue = null;
                        }
                      }
                    }
                  }
                  const selectedDropdownValue = leadSelectedDropdownValue;
                  allVisbleAndMandatoryFieldsWithApiKey.current = {
                    ...allVisbleAndMandatoryFieldsWithApiKey.current,
                    [fieldKey]: apiKey,
                  };
                  // Custom styles to hide the cross icon when the multi-select is disabled
                  const customStyles = {
                    multiValueRemove: (base) => ({
                      ...base,
                      display: disabled ? "none" : base.display,
                    }),
                  };
                  let isSelectDisabled = disabled;
                  if (
                    get(disableFieldsOnValue, `isActive`) &&
                    checkIfArray(get(disableFieldsOnValue, `disableFields`)) &&
                    disableFieldsOnValue.disableFields.includes(fieldKey)
                  ) {
                    isSelectDisabled =
                      get(
                        userDetail,
                        WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY[apiKey]
                      ) || false;
                  }
                  return (
                    <FormGroup
                      key={fieldKey}
                      className={`col-12 col-md-6 form-group ${
                        selectDropdownError ? "dropwon-error" : ""
                      }`}
                    >
                      <Label for={fieldKey}>
                        {!!label ? label : ""}
                        {required && (
                          <span className="required-notation">{" *"}</span>
                        )}
                      </Label>
                      <Select
                        options={dropDownCollection}
                        isMulti={multiple}
                        name={fieldKey}
                        fieldKey={fieldKey}
                        placeholder={placeholder}
                        className={
                          isSelectDisabled
                            ? "custom-react-select disabled"
                            : "custom-react-select"
                        }
                        isClearable={true}
                        onChange={(e) => {
                          onChangeDropdown(
                            e,
                            widgetFormFields[fieldKey],
                            fieldKey,
                            widgetFormFields
                          );
                        }}
                        getOptionLabel={({ name }) => name}
                        getOptionValue={({ id }) => id}
                        value={selectedDropdownValue}
                        disabled={isSelectDisabled}
                        isDisabled={isSelectDisabled}
                        styles={customStyles}
                      />
                      {selectDropdownError && (
                        <FormFeedback className="input-validation-error-messsage">
                          {selectDropdownError}
                        </FormFeedback>
                      )}
                    </FormGroup>
                  );
                default:
                  break;
              }
            }
          })}
      </div>
    );
  };

  const onSucceessAddlead = (userDetail, updatedINSTWiseAllConfigs = null) => {
    const verificationData = get(props, "userDetail.lead.verificationData");
    if (verificationData) {
      const updatedUserDetails = {
        ...(userDetail || {}),
        lead: {
          ...(userDetail.lead || {}),
          verificationData,
        },
      };
      userDetail = updatedUserDetails;
    }
    leadObjectManager.init(userDetail, masterData);
    userDetailSuccess(userDetail);
    updateFormDataInUkc();
    if (get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA) || checkIfObject(updatedINSTWiseAllConfigs)) {
      const leadId = get(userDetail, "lead.id");
      const inst =
        get(userDetail, `instValue`) ||
        getApplicationFormCriteriaValue(userDetail, masterData);
        const ukcValue = getUniqueKeyCriteriaValue(userDetail,masterData) || OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,STORAGE_TYPE.LOCAL);
      inst &&
        OfflineStorage.setItem(
          STORAGE_KEY.VERIFIED_INST,
          inst,
          STORAGE_TYPE.LOCAL
        );
      const localStoragelid = OfflineStorage.getItem(
        STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,
        STORAGE_TYPE.LOCAL
      );
      localStoragelid &&
        OfflineStorage.setItem(
          STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,
          leadId,
          STORAGE_TYPE.LOCAL
        );
      OfflineStorage.setItem(
        STORAGE_KEY.RESET_FORM_DATA,
        true,
        STORAGE_TYPE.LOCAL
      );
      if (inst) {
        const allConfigs = updatedINSTWiseAllConfigs || instwiseAllConfigs;
        //Do not change sequence this code it is alway at last postion
        i18n.options.defaultNS = `${inst}-${Configuration.activeLanguage}`;
        OfflineStorage.setItem(STORAGE_KEY.INST, inst, STORAGE_TYPE.LOCAL);
        allConfigs &&
          allConfigs[inst] &&
          updateAllConfigurationsAccordingToInst(allConfigs[inst]);
      }
      const UKC_Details = getUKCDetails(masterData, userDetail);
      if (get(UKC_Details, STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY)) {
        OfflineStorage.setItem(
          STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY,
          get(UKC_Details, STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY),
          STORAGE_TYPE.LOCAL
        );
        if (leadId) {
          const accessToken = get(userDetail, "accessToken");
          getAndUpdateTokenInUKC({ leadId, accessToken, ukc:ukcValue });
        }
      }
    }
    setShowWidgetPopup(false);
    openApplicationForm({
      openApplicationFormStep: "current",
      clientInfo,
      userDetail,
      masterData,
      appFormCompletedStepIndex: [0],
      dashboardConfig,
      formConfig,
      history,
      historyStates: {
        fromDashboard: window.location.pathname === PAGES.DASHBOARD,
      },
      formData
    });
  };

  const validateFields = () => {
    let errorObj = cloneDeep(error || {});
    let apiParams = {};
    checkIfObject(allVisbleAndMandatoryFieldsWithApiKey.current) &&
      Object.keys(allVisbleAndMandatoryFieldsWithApiKey.current).forEach(
        (fieldKey) => {
          let { label, apiKey, pattern, type } =
            widgetFormFields[fieldKey] || {};
          apiKey = apiKey ? apiKey : fieldKey;
          if (!pattern) {
            pattern = FORM_INPUT_PATTERN[type];
          }
          if (data && data[fieldKey] && apiKey) {
            let updatedData = data[fieldKey];
            if (apiKey.includes("Arr")) {
              updatedData = checkIfArray(updatedData)
                ? updatedData
                : [updatedData];
              if (apiKey.includes("Ids")) {
                updatedData = updatedData.map((obj) => obj.id);
              } else {
                updatedData = updatedData.map((obj) => obj.name);
              }
            } else if (checkIfArray(updatedData)) {
              updatedData = updatedData.map((obj) => obj.name).join(",");
            }
            if (pattern && !validateByType(updatedData, type, pattern)) {
              errorObj = {
                ...errorObj,
                [fieldKey]: inValidMessage.replace("$fieldName$", label),
              };
            } else {
              apiParams = {
                ...apiParams,
                [apiKey]: checkIfObject(updatedData)
                  ? updatedData?.name
                  : updatedData,
              };
            }
          } else {
            errorObj = {
              ...errorObj,
              [fieldKey]: requiredMessage.replace("$fieldName$", label),
            };
          }
        }
      );
    if(!isMobileVerified && widgetOtpConfig && !!get(widgetOtpConfig, `visible`)){
      errorObj = {
        ...errorObj,
        [API_KEY_NAME.MOBILE_OTP]: otpErrorValidatonMessage
      }
    }
    return { errorObj, apiParams };
  };

  const onSendOtp = async (fieldKey, fieldConfig) => {
    setSendOtpLoader(true);
    const entityId = ukcValue || getEntityIdBasedOnUKC() || 0;
      const isAPIParamsPresent = !isEmpty(data[API_KEY_NAME.MOBILE]) && get(userDetail, "lead.userId") &&
        get(userDetail, "lead.id");
      if(isAPIParamsPresent){
        let apiEndpoint = `${get(window,"extraaedge.API_END_POINT.check_Mobile_Exists")}?mobileNumber=${data[API_KEY_NAME.MOBILE]}&entityId=${entityId}&isExactMatch=true&userId=${userDetail.lead.userId}&leadId=${userDetail.lead.id}`;
        await checkIfEmailMobileExists(API_KEY_NAME.MOBILE, fieldConfig, apiEndpoint, data[API_KEY_NAME.MOBILE], true);
      }else{
        const mobileError = get(error, API_KEY_NAME.MOBILE_OTP) ||  get(error, fieldKey);
        if(!mobileError || (mobileError === otpErrorValidatonMessage)){
          showSendOTPModal()
          setSendOtpLoader(false);
        }
      }
  }
  const onSubmit = () => {
    if (isEmpty(isAlredyExistAPIIsInpregress.current)) {
      const { errorObj, apiParams: webhookApiParams } = validateFields();
      if (checkIfObject(errorObj)) {
        setError(errorObj);
        isSaveBtnClicked.current = true;
      } else if (checkIfObject(webhookApiParams)) {
        const {
          Info: { authToken, source },
        } = clientInfo || {};
        webhookAddLead({
          apiParams: {
            ...(apiRequestDefaultParameter || {}),
            AuthToken: authToken,
            Source: source,
            eesourceid: 16,
            IsGetAllLeadDetails: true,
            ...webhookApiParams,
          },
          onSucceessAddlead,
          webhookAddLeadCallFrom: "widgetPopup",
        });
      }
    } else {
      console.log("Is alredy exist API isinprogres please wait...");
    }
  };

  const showSendOTPModal = () => {
    setShowVerifyModal((prevState) => !prevState);
    !isMobileOTPCalled && setIsMobileOTPCalled(true);
    setSendOTPTrigger(VERIFICATION_AND_EDIT_ACTION_ITEMS.WIDGET_VERIFY_MOBILE);
  }

  return (
    <div className="widget-popup">
      {isActive && widgetFormConfig && showWidgetPopup && (
        <GenricModal
          show={showWidgetPopup}
          toggleModal={() => {}}
          modalTitle={modalTitle}
          modalBody={renderModalBody()}
          modalProps={{
            className: "generic-modal widget-modal",
            centered: true,
          }}
          actionBtnlabel={submitBtnLabel}
          okFunction={onSubmit}
          showButtonLoader={isInProgress}
          disabledModalOkBtn={
            isInProgress || !isEmpty(isAlredyExistAPIIsInpregress.current)
          }
        />
      )}
      {showWidgetPopup && sendOTPTrigger && showVerifyModal &&(
        <VerificationModalPopup actionItem={sendOTPTrigger} mobileInput={data && data[API_KEY_NAME.MOBILE]} setVerificationDataArray={setVerificationDataArray} verificationDataArray={verificationDataArray} ukcValue={ukcValue || 0}
        />
      )}
      {webHookAddLeadInProgress && (
        <div className="zipy-block">
          <div className="api-loader">
            <div className="animation" />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  masterData: state.appDataReducer && state.appDataReducer.masterData,
  formData: state.formReducer && state.formReducer.formData,
  userDetail: state.appDataReducer && state.appDataReducer.userDetail,
  domainConfig: state.appDataReducer && state.appDataReducer.domainConfig,
  clientInfo: state.appDataReducer && state.appDataReducer.clientInfo,
  formConfig: state.formReducer && state.formReducer.data,
  dashboardConfig: state.dashboardReducer && state.dashboardReducer.data,
  isCounselorAccessActive:
    state.appDataReducer && state.appDataReducer.isCounselorAccessActive,
  widgetFormConfig:
    state.allApplicationsReducer &&
    state.allApplicationsReducer.widgetFormConfig,
  fetchWidgetFormConfigIsInProgress:
    state.allApplicationsReducer && state.allApplicationsReducer.isInProgress,
  instwiseAllConfigs:
    state.appDataReducer && state.appDataReducer.instwiseAllConfigs,
  webHookAddLeadInProgress:
    state.allApplicationsReducer &&
    state.allApplicationsReducer.webHookAddLeadInProgress,
  allApplicationsData:
    state.appDataReducer && state.appDataReducer.allApplicationsData,
  isAllApplicationDataAPICalled:
    state.appDataReducer &&
    state.appDataReducer.isAllApplicationDataAPICalled,
  isAllApplicationDataFailed:
    state.appDataReducer && state.appDataReducer.isAllApplicationDataFailed,
  showVerifyModal: state.formReducer && state.formReducer.showVerifyModal,
});

export default connect(mapStateToProps, {
  getWidgetFormConfig,
  userDetailSuccess,
  updateAllConfigurationsAccordingToInst,
  updateFormDataInUkc,
  getAndUpdateTokenInUKC,
  webhookAddLead,
  setShowVerifyModal
})(WidgetPopup);
