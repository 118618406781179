import Axios from "axios";
import MockAdapter from "axios-mock-adapter";
import {
  REQUEST_API,
  STORAGE_KEY,
  STORAGE_TYPE,
} from "../../shared/utilties/Constants";
import { OfflineStorage } from "../../shared/utilties/OfflineStorage";
import { Configuration } from "../configuration/appsettings";
import { MockFunctions } from "./SetupMocks";
import { checkIfObject, getCustomHeaderParams, isValidUrl } from "../../shared/utilties/Utils";

if (Configuration.useMock) {
  const mock = new MockAdapter(Axios, {
    delayResponse: Math.max(1000, Math.floor(Math.random() * 2000)),
  });

  //Register all mocks.
  MockFunctions.map((mf) => mf(mock));
}

function createAPI(baseURL) {
  const API = Axios.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  API.interceptors.request.use(
    async function (config) {
      const token = getAccessToken();
      if (token !== "NO_TOKEN") {
        config.headers.Authorization = `Bearer ${token}`;
      }
      const {url,data="",baseURL=""} = config;
      const actualUrl = isValidUrl(url) ? url : `${baseURL}${url}`;
      let isUseFormDataForSignature =false;
      try {
        const {pathname=""} = new URL(actualUrl);
        if(pathname==='/token'){
          isUseFormDataForSignature=true;
        }
      } catch (error) {
      }
      let customHeaderParams = {};
      try {
        customHeaderParams = await getCustomHeaderParams(
          actualUrl,
          data && ((isUseFormDataForSignature && data instanceof FormData) || checkIfObject(data)) ? data : ""
        );
      } catch (error) {
        customHeaderParams = {}
      }
      config.headers = {...config.headers,...customHeaderParams};
      return config;
    },
    function (err) {
      return Promise.reject(err);
    }
  );

  API.interceptors.response.use(
    function (response) {
      // Handle successful responses here
      return response;
    },
    function (error) {
      // Handle errors here
      return Promise.reject(error);
    }
  );

  function getAccessToken() {
    return !!OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    )
      ? OfflineStorage.getItem(STORAGE_KEY.ACCESS_TOKEN, STORAGE_TYPE.LOCAL)
      : "NO_TOKEN";
  }

  return API;
}

export const API = createAPI(REQUEST_API.BASE_URL);
export const QUERYAPI = createAPI(REQUEST_API.QUERY_BASE_URL);
export const SLOTAPI = createAPI(REQUEST_API.SLOT_BASE_URL);
